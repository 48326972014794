import { useHistory } from "react-router";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import SimpleHeader from "../components/Headers/SimpleHeader";

export const Shortcut = ({ view }) => {
    let history = useHistory();
    return (
        <Col
            className="shortcut-item"
            href={"/"}
            onClick={
                (e) => {
                    e.preventDefault()
                    history.push(view.layout + view.path)
                }
            }
            xs="4"
            md={"3"}
            tag="a"
        >
              <span className="shortcut-media avatar rounded-circle bg-danger">
                <i className={ view.icon }/>
              </span>
            <small>{ view.name }</small>
        </Col>
    )
}

export const Home = ({ routes }) => {

    return (
        <>
            <SimpleHeader name={"Home"}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Card className={"mt--6"}>
                            <CardBody>
                                <Row className="shortcuts px-2">
                                    {
                                        routes.map(route => {
                                            return route.views.map(view => <Shortcut key={`${route.name}_${view.name}`} view={ view }/>)
                                        })
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Home;