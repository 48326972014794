

import { AlgoliaProvider } from "./contexts/algolia-context-provider";
import { FuegoProvider } from "@nandorojo/swr-firestore";

import { initFirebase } from "./init/initFirebase";
import { initAlgolia } from "./init/initAlgolia";
import "./init/initConsole";

import AppAuthLayer from "./AppAuthLayer";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/nucleo/css/nucleo.css";

import "./assets/scss/argon-dashboard-pro-react.scss";


const fuego = initFirebase();
const algolia = initAlgolia();



export const App = () => {
    return (
        <div className={"App"}>
            <FuegoProvider fuego={fuego}>
                <AlgoliaProvider value={algolia}>
                    <AppAuthLayer />
                </AlgoliaProvider>
            </FuegoProvider>

        </div>

    );
}

export default App;
