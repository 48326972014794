import { useCollection } from "@nandorojo/swr-firestore";

export default function useFetchCollection({ model }) {

    let { collectionName, limit, orderBy, dateFields, paginate } = model;

    let queryOptions = {
        // listen: true,
        limit,
        orderBy,
        parseDates: dateFields,
    };
    // swrOptions = {
    //     initialData: [],
    //     revalidateOnMount: true,
    //     onSuccess(docs) {
    //         if (docs.length === limit) setHasMore(true);
    //     }
    // };

    if (paginate) {
        queryOptions.ignoreFirestoreDocumentSnapshotField = false;
        // swrOptions = {
        //     ...swrOptions,
        //     revalidateOnFocus: false,
        //     refreshWhenHidden: false,
        //     refreshWhenOffline: false,
        //     refreshInterval: 0
        //
        // }
    }

    let { data: initialData } = useCollection(collectionName,
        {
            limit,
            orderBy,
            parseDates: dateFields,
        },
        {});

    // let revalidated = useRef(false);
    let { data, add } = useCollection(collectionName, {
            orderBy,
            parseDates: dateFields,
            listen: true,
            // limit: 50
        },
        {
            // initialData: firstTen,
            // revalidateOnMount: true,
            // onSuccess(docs) {
            //     console.log("docs", docs)
            // }
        })

    return { initialData, data, add }
}