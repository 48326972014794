import { FormGroup, Input, InputGroup } from "reactstrap";
import classnames from "classnames";

export const TextareaInput = ({ elementId, label, rows, value, setValue, focus, setFocus, autoFocus, info, resizable }) => {
    return (
        <FormGroup>
            <label
                className="form-control-label"
                htmlFor={elementId}
            >{ label }</label>
            <InputGroup
                className={classnames("input-group-merge", {
                    focused: focus,
                })}
            >
                <Input
                    autoFocus={ autoFocus }
                    id={elementId}
                    value={ value }

                    onInput={e => setValue(e.currentTarget.value)}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    rows={ rows || 3}
                    type="textarea"
                    resize={ resizable ? "" : "none"}
                />
            </InputGroup>
            {
                info
                    ?
                    <div className="valid-feedback d-block text-danger">{info}</div>
                    :
                    null
            }
        </FormGroup>
    )
}

export default TextareaInput;
