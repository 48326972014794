import { Button, Col, FormGroup, Row } from "reactstrap";
import MiniProfile from "../Subscribers/View/MiniProfile";

export const MiniProfileField = ({ introducer, label, onDelete }) => {
    return (
        <FormGroup>
            <label className="form-control-label">
                { label || "Introduced by"}
            </label>
            <div className={"mb-2"}>
                <MiniProfile subscriber={ introducer } onDelete={ onDelete }/>
            </div>
            <Row >
                <Col className={"d-flex justify-content-end pt-3"}>
                    <Button
                        className={"btn-sm"}
                        color={"default"}
                        onClick={ e => {
                            e.preventDefault()
                            // onSaveAndCreatePayment(introducer, {
                            //     message: `소개모집 ()`
                            // })
                        }}
                    >SAVE AND CREATE PAYMENT</Button>
                </Col>

            </Row>
        </FormGroup>
    )
}

export default MiniProfileField;