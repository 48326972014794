import fields from "./fields"

export const defaults = {
    [fields.progress_basic_settings]: "",
    [fields.progress_insta_marketing]: "",
    [fields.progress_shopping_mall]: "",
    [fields.progress_cafe]: "",
    [fields.progress_rooms]: ""
}

export default defaults