import { useState } from "react";
import moment from "moment";

export default function useRemoteOp({ addOp }) {
    let [ updateDisabled, setUpdateDisabled ] = useState(false);
    let [ updateLoading, setUpdateLoading ] = useState(false);

    let onUpdateSubscribers = () => {

        setUpdateDisabled(true);
        setUpdateLoading(true);

        addOp({
            task: "UPDATE_SUBSCRIBERS",
            created: moment().toDate(),
            status: "QUEUED"
        })

        setTimeout(() => {
            setUpdateDisabled(false);
            setUpdateLoading(false);
        }, 5000)
    }

    let onUpdateNaverBlog = () => {

        setUpdateDisabled(true);
        setUpdateLoading(true);

        addOp({
            task: "UPDATE_NAVER_BLOG",
            created: moment().toDate(),
            status: "QUEUED"
        })

        setTimeout(() => {
            setUpdateDisabled(false);
            setUpdateLoading(false);
        }, 5000)
    }

    return {
        onUpdateNaverBlog,
        onUpdateSubscribers,
        updateDisabled,
        updateLoading
    }
}

