import { convertDates } from "../firebase/convertDates";
import { getSelectedDropdowns } from "../utilities/formsUtils";
import moment from "moment";
import { last } from "lodash";

import progress from "../classes/progress"
import notes from "../classes/notes"

const collectionName = "shoplinkers"
const snapshotCollection = "shoplink_snapshots"

const filterers = [
    { label: "All Shoplinkers", value: "all", filter: () => true },
    {
        label: "인스타 비즈니스 계정 설정한 수강생",
        value: "discoverable",
        filter: x => x.instagram_username && x.discoverable
    },
    { label: "마켓명 없음", value: "no_market_name", filter: x => !x.market_name},
    { label: "인스타 ID 없음", value: "no_instagram_username", filter: x => !x.instagram_username},
]

const limit = 10;
const formTab = "샵링크";
const pageName = "샵링크 수강생";
const newestFirst = true;
const indexName = collectionName;

export const fields = {
    name: "name",
    payment_name: "payment_name",
    market_name: "market_name",
    instagram_username: "instagram_username", // Basic Profile

    ...notes.fields,
    ...progress.fields,

    // Agit Shop
    introduced_by_id: "introduced_by_id",
    join_date: "join_date",
    subscribe_date: "subscribe_date",
    subscriber_date: "subscriber_date",
    subscribe_month: "subscribe_month",
    subscription_type: "subscription_type",
    market: "market",

    nickname: "nickname",
    instagram_id: "instagram_id",
    instagram_name: "instagram_name",

    profile_picture_url: "profile_picture_url",
    media_count: "media_count",
    discoverable: "discoverable",

    mobile: "mobile",
    customer_id: "customer_id", // hashed mobile as anonymous id for customer db

    phone: "phone",
    address: "address",
    zip_code: "zip_code",

    mall_id: "mall_id",

    first_name: "first_name",
    last_name: "last_name",


    eligible_rookie: "eligible_rookie",

    // Kakao
    kakao_username: "kakao_username",
    kakao_id: "kakao_id",
    kakao_profile_image_url: "kakao_profile_image_url",
    kakao_nickname: "kakao_nickname",
    kakao_phone_number: "kakao_phone_number",  // +00 00-0000-0000 or +00 00 0000 0000 format
    kakao_email: "kakao_email",
    kakao_ci: "kakao_ci",
    kakao_ci_authenticated_at: "kakao_ci_authenticated_at",
    kakao_open_chat: "kakao_open_chat",

    gmail_address: "gmail_address"

}


const searchKeys = [
    fields.name,
    fields.market_name,
    fields.instagram_username,
    fields.mobile,
    fields.mall_id
];

export const dateFields = [
    fields.subscriber_date,
    fields.join_date
]

export const defaults = {
    [fields.name]: "",
    [fields.payment_name]: "",
    [fields.market_name]: "",
    [fields.instagram_username]: "", // Basic Profile

    ...notes.defaults,
    ...progress.defaults,

    // Agit Shop
    [fields.introduced_by_id]: "",
    [fields.join_date]: null,
    [fields.subscribe_date]: "",
    [fields.subscriber_date]: null,
    [fields.subscribe_month]: "",
    [fields.subscription_type]: "",
    [fields.market]: "",

    [fields.nickname]: "",
    [fields.instagram_id]: "",
    [fields.instagram_name]: "",

    [fields.profile_picture_url]: "",
    [fields.media_count]: 0,
    [fields.discoverable]: false,

    [fields.mobile]: "",
    [fields.customer_id]: "", // hashed mobile as anonymous id for customer db

    [fields.phone]: "",
    [fields.address]: "",
    [fields.zip_code]: "",

    [fields.mall_id]: "",

    [fields.first_name]: "",
    [fields.last_name]: "",


    [fields.eligible_rookie]: false,

    // Kakao
    [fields.kakao_username]: "",
    [fields.kakao_id]: "",
    [fields.kakao_profile_image_url]: "",
    [fields.kakao_nickname]: "",
    [fields.kakao_phone_number]: "",  // +00 00-0000-0000 or +00 00 0000 0000 format
    [fields.kakao_email]: "",
    [fields.kakao_ci]: "",
    [fields.kakao_ci_authenticated_at]: null,
    [fields.kakao_open_chat]: "",

    [fields.gmail_address]: ""
}

export const getNew = () => {
    return {
        ...defaults,
        [fields.subscriber_date]: moment()
    }
}

export const dropdowns = {
    ...progress.dropdowns
}

export const autocompletes = {
    [fields.introduced_by_id]: {
        collection: collectionName,
        formatItem: (item) => {
            let label = `${item.name} 사장`;
            if (item.market_name) label = `${item.market_name} (${item.name} 사장)`
            return {
                label, value: item.id
            }
        }
    }
}

export const labels = {
    ...progress.labels,
    ...notes.labels,
    [fields.mall_id]: "샵링크 ID",
    [fields.subscriber_date]: "결제원료일"
}

const getProgressByStages = (subscriber) => {
    return Object.values(progress.fields).map(step => {
        let status = subscriber[step],
            dropdowns = progress.dropdowns[step],
            label = progress.labels[step],
            lastStep = dropdowns[dropdowns.length - 1],
            color = lastStep ? "success" : "info";

        if (!status) return null;

        return {
            step,
            color,
            status,
            label
        }
    })
}

export const Shoplinker = {
    collectionName,
    snapshotCollection,
    filterers,
    pageName,
    formTab,
    indexName,
    searchKeys,

    newestFirst,
    limit,
    orderBy: [ fields.subscriber_date, "desc" ],
    paginate: !!limit,

    fields,
    labels,
    dropdowns,
    autocompletes,
    getNew,
    defaults,
    dateFields,
    getSubscriberDateStr(subscriber) {
        let subscriberDate = moment(subscriber.subscriber_date),
            fmt = "YYYY년 M월 D일";
        if (!subscriberDate.isValid()) subscriberDate = moment(subscriber.subscriber_date.toDate());
        return subscriberDate.format(fmt)
    },
    convertDates(item) {
        return convertDates(item, dateFields)
    },
    getSelectedDropdowns(item) {
        return getSelectedDropdowns(dropdowns)(item)
    },
    getProgressByStages,
    getMostRecentProgress(subscriber) {
        let progresses = getProgressByStages(subscriber).filter(x => !!x)
        return last(progresses)
    },
    getItemPath(item) {
        return `${collectionName}/${item.id}`
    },
}

export default Shoplinker;
