import React, { useEffect, useState } from "react";
import moment from "moment";
import { CardBody, Form, Row, Col } from "reactstrap";
import { fuego } from "@nandorojo/swr-firestore";

import TextInput from "../../Forms/TextInput";
import DatetimeInput from "../../Forms/DatetimeInput";
// import AutocompleteInput from "../../Forms/AutocompleteInput";
import FuseAutocomplete from "../../Forms/FuseAutocomplete";
import MiniProfileField from "../../Forms/MiniProfileField";



export const AgitShopForm = ({model, subscriber, getInputProps, setValue, fuse}) => {

    let { fields, labels, collectionName, autocompletes } = model;

    let [introducer, setIntroducer] = useState(null);

    let {
        introduced_by_id,
        mall_id,
        subscriber_date
    } = fields;

    let firebase = fuego;

    useEffect(() => {
        if (!subscriber.introduced_by_id) return;
        let db = firebase.firestore(),
            col = db.collection(collectionName);

        return col.doc(subscriber.introduced_by_id)
            .get()
            .then(snapshot => {
                setIntroducer({
                    ...snapshot.data(),
                    id: snapshot.id
                })
            })
    }, [firebase, subscriber.introduced_by_id, collectionName])

    // useEffect(() => {
    //     console.log("agit fuse", fuse)
    // }, [
    //     fuse
    // ])

    return (
        <CardBody>
            <Form>
                <Row>
                    <Col md="6">
                        <TextInput
                            { ...getInputProps(subscriber, mall_id, "Agit Shop ID")}
                            info={"e.g. lsy8073, lovemom"}
                        />
                        {
                            (subscriber[introduced_by_id] && introducer)
                            ?

                                <MiniProfileField
                                    introducer={ introducer }
                                    label={ labels[introduced_by_id] }
                                    onDelete={ () => setValue(introduced_by_id)("") }
                                />

                                :
                                <FuseAutocomplete
                                    {...getInputProps(subscriber, introduced_by_id, "Introduced by")}
                                    fuse={ fuse }
                                    autocomplete={ autocompletes[introduced_by_id]}
                                />
                        }

                        <DatetimeInput
                            label={labels[subscriber_date] || "Subscriber Date"}
                            value={ subscriber[subscriber_date]}
                            setValue={ (val) => {
                                let date = moment(val)
                                setValue("subscribe_date")(date.format("YYYY-MM-DD"))
                                setValue("subscribe_month")(date.format("YYYYMM"))
                                setValue(subscriber_date)(date.toDate())
                            }}
                        />

                    </Col>
                </Row>
            </Form>
        </CardBody>
    )
}

export default AgitShopForm;
