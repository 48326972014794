import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { fuego } from "@nandorojo/swr-firestore";

import GoogleLayer from "./GoogleLayer";
import UserLayer from "./UserLayer";

import AdminLayout from "./AdminLayout";
import Login from "./pages/Login";
import Loading from "./pages/Loading";


let googleApiSignout = (firebase) => {
    if (window.gapi && window.gapi.auth2) {

        const auth2 = window.gapi.auth2.getAuthInstance()
        if (!auth2.isSignedIn.get()) {
            console.log('Not signed in!')
            return
        }

        auth2.signOut()
            .then(() => {
                console.log('gapi: sign out complete')
            })
            .then(() => {
                return firebase.auth().signOut()
            })
            .then(() => {
                console.log('firebase: sign out complete')
            })

    }
}

export const AppAuthLayer = () => {

    const firebase = fuego;

    let [ email, setEmail ] = useState(null);
    let [ user, setUser ] = useState(null);

    const loginMessages = {
        ACCESS_DENIED: "Access denied ... ",
        LOGGING_IN: "Checking login status...",
        LOADING: "Checking login status..."
    }

    let [ loginError, setLoginError ] = useState(loginMessages.LOADING);
    let [ onLogout, setOnLogout ] = useState([]);

    let logout = () => {
        setEmail(null)
        setUser(null)
        setLoginError("")
        onLogout.forEach(logoutFunc => {
            try {
                logoutFunc()
            } catch (err) {
                console.log(err)
            }
        })
    }

    let handleSignIn = () => {
        if (window.gapi && window.gapi.auth2) {
            const auth2 = window.gapi.auth2.getAuthInstance()
            if (auth2.isSignedIn.get()) {
                // console.log('gapi already signed in')
                return
            }

            auth2.signIn()
                .catch(error => {
                    console.log(`sign in error:`, error)
                })
        }
    }

    let handleSignOut = () => {
        logout();
        googleApiSignout(firebase);
    }

    // handleSignOut();

    return (
        <GoogleLayer
            resetUser={ setUser }
            setEmail={ setEmail }
            loginMessages={loginMessages}
            loginError={loginError}
            setOnLogout={setOnLogout}
            setLoginError={setLoginError}>
            {email ?
                <UserLayer
                    email={ email }
                    setEmail={ setEmail }
                    setUser={ setUser }
                    loginMessages={ loginMessages }
                    setLoginError={setLoginError}
                    signUserOut={ () => {
                        setLoginError(loginMessages.ACCESS_DENIED)
                        googleApiSignout(firebase)
                    } }
                >
                    { user ?
                        <BrowserRouter>
                            <AdminLayout onLogout={handleSignOut}/>
                        </BrowserRouter>
                        : <Loading />}
                </UserLayer>
                :
                <Login
                    onGoogleSignIn={handleSignIn}
                    error={loginError}
                    loginMessages={ loginMessages }
                    onError={(msg) => setLoginError(msg)}/>
            }
        </GoogleLayer>
    )
}

export default AppAuthLayer;
