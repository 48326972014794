import React, { useState, useEffect } from "react";
import {
    Button, Col, Container, Row, Spinner
} from "reactstrap";
import { chain, isEmpty } from "lodash";

import SubscriberProfile from "./SubscriberProfile";


export const SubscribersList = ({ model, items, onView, searchResults, searchTerm, filters, setCurrent }) => {

    const pageSize = 30
    let [ limit, setLimit ] = useState(pageSize);

    useEffect(() => setCurrent(null), [setCurrent])


    let showSubscribers = () => {
        if (searchTerm && isEmpty(searchResults)) {
            return (
                <Col className={"d-flex justify-content-center mt--6"}>
                    <h4 className={"text-white pt-3"}>No subscribers to show.</h4>
                </Col>
            )
        }
        if (searchTerm) {
            return searchResults.map(({ item: hit }, idx) => <SubscriberProfile
                model={ model }
                onView={onView}
                key={hit.id}
                hit={hit}
                item={hit}
                idx={idx}/>)
        }
        return chain(items)
            .filter(filters.current.filter)
            .slice(0, limit)
            .map((hit, idx) => <SubscriberProfile
                model={ model }
                onView={onView}
                key={hit.id}
                hit={hit}
                item={hit}
                idx={idx}/>)
            .value()
    }

    return (
        <Container fluid>
            {
                !items ?
                    <Row className={"justify-content-center mt--6"}>
                        <Spinner color="light" style={{ width: '3rem', height: '3rem' }}/>
                    </Row>

                    :
                    <>
                        <Row>
                            {showSubscribers()}
                        </Row>
                        <Row>
                            <Col className={"d-flex justify-content-center my-3"}>
                                {!searchTerm && items.length > limit ?
                                    <Button
                                        color={"danger"}
                                        onClick={() => {
                                            // getNext()
                                            setLimit(prev => prev + pageSize)
                                        }}
                                    >Load More</Button>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </>

            }

        </Container>
    )

}

export default SubscribersList;