import fields from "./fields"
import labels from "./labels"
import dropdowns from "./dropdowns"
import defaults from "./defaults";

export const progress = {
    fields,
    labels,
    dropdowns,
    defaults
}

export default progress
