// import React, { useEffect } from "react";

import { CardBody, Row } from "reactstrap";
import SubscriberSnapshot from "./SubscriberSnapshot";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const SubscriberDetails = ({ item, model }) => {
    // useEffect(() => { console.log ("details", item)}, [item])

    return (
        <CardBody className="py-0">
            {item.discoverable && item.media_count
                ?
                <Row>
                    <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center py-0">
                            <div>
                                <span className="heading">{numberWithCommas(item.media_count || 0)}</span>
                                <span className="description">개시물</span>
                            </div>
                            <div>
                                <span className="heading">{numberWithCommas(item.followers_count)}</span>
                                <span className="description">Followers</span>
                            </div>
                            <div>
                                <span className="heading">{numberWithCommas(item.follows_count)}</span>
                                <span className="description">Following</span>
                            </div>
                        </div>
                    </div>
                </Row>
                :
                null
            }
            {
                item.discoverable && item.media_count
                    ?
                    <SubscriberSnapshot item={item} model={ model }/>

                    : null
            }

            {/*<div className="text-center">*/}
            {/*    <h5 className="h3">*/}
            {/*        {hit.name}*/}
            {/*        <span className="font-weight-light">{ hit.market_name ? ` (${hit.market_name})` : "" }</span>*/}
            {/*    </h5>*/}
            {/*    /!*<div className="h5 font-weight-300">*!/*/}
            {/*    /!*    <i className="ni location_pin mr-2" />*!/*/}
            {/*    /!*    Bucharest, Romania*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className="h5 mt-4">*!/*/}
            {/*    /!*    <i className="ni business_briefcase-24 mr-2" />*!/*/}
            {/*    /!*    Solution Manager - Creative Tim Officer*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div>*!/*/}
            {/*    /!*    <i className="ni education_hat mr-2" />*!/*/}
            {/*    /!*    University of Computer Science*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
        </CardBody>
    )
}

export default SubscriberDetails;
