import SimpleHeader from "../components/Headers/SimpleHeader";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";


export default function NewRoute({ paths, model, setCurrent, fuse, clone, setClone, onSave, onCancel, View }) {

    let handleBreadcrumbClick = useBreadcrumbs({ setCurrent, paths })

    let { indexName, getNew, pageName } = model;

    return (
        <>
            <SimpleHeader name={"NEW"}
                          parentName={pageName}
                          onParentClick={handleBreadcrumbClick}/>
            <View
                model={ model }
                fuse={ fuse }
                item={clone || getNew()}
                setItem={setClone}
                indexName={indexName}
                onSave={onSave}
                onCancel={onCancel}/>
        </>


    )
}