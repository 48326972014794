// import React, { useState } from "react";
import { FormGroup } from "reactstrap";
// import Select from "react-select";
// import { debounce } from "lodash";
import AsyncSelect from "react-select/async";

export const FuseAutocomplete = ({ fuse, autocomplete, elementId, label, setValue, info, allowSearch, placeholder }) => {

    // let [ searchTerm, setSearchTerm ] = useState("");
    // let [ searchResults, setSearchResults ] = useState([]);
    //
    // let refineSearch = async (searchTerm) => {
    //     if (!fuse.current) return;
    //     console.log("refining search", searchTerm)
    //     if (!searchTerm) {
    //         // setSearchTerm(searchTerm)
    //         setSearchResults([]);
    //         return;
    //     }
    //
    //     let results = fuse.current.search(`'${searchTerm}`)
    //     // setSearchTerm(searchTerm)
    //     return results;
    //     // setSearchResults(results)
    //
    // }
    // , 300, { trailing: true })

    let options = {
        placeholder: placeholder || "Select one",
    };

    if (!allowSearch) options.minimumResultsForSearch = -1;

    let loadOptions = async (searchTerm) => {
        // let index = hits.find(hit => hit.index === indexName)
        // console.log("loading options", searchTerm, fuse, fuse.current._docs.length)
        let results = searchTerm ? fuse.current.search(`'${searchTerm}`) :
            fuse.current._docs.map((doc, idx) => { return {
                item: doc,
                score: 1,
                refIndex: idx
            }})
        results = results.map(doc => doc.item)
        // console.log(results)
        // let x = results.map(({ item }) => {
        //     let label = item.name;
        //     if (item.market_name) label = `${item.market_name} (${item.name})`
        //     return {
        //         label, value: item.id
        //     }
        // })
        // console.log(x)
        return results.map(autocomplete.formatItem)
    }

    // useEffect(() => {
    //     refine("")
    // })

    return (
            <FormGroup >
                <label
                    className="form-control-label"
                    htmlFor={elementId}
                >{ label }</label>
                <AsyncSelect
                    id={elementId}
                    className="form-control px-0 pt-0 border-0"
                    // value={ value }
                    defaultOptions
                    loadOptions={ loadOptions }
                    onInputChange={val => {
                        // refineSearch(val)
                        // loadOptions()
                    }}
                    onChange={(option) => {
                        setValue(option.value)
                    }}
                    styles={{
                        valueContainer: (provided) => {
                            return {
                                ...provided,
                                paddingTop: ".625rem",
                                paddingBottom: ".625rem"
                            }
                        }
                    }}
                />
                {
                    info
                        ?
                        <div className="valid-feedback d-block text-danger">{info}</div>
                        :
                        null
                }
            </FormGroup>
    )
}

export default FuseAutocomplete;
