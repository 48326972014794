// import { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router";

export default function useRoutePaths() {
    let routeMatch = useRouteMatch(),
        history = useHistory();

    // useEffect(() => {console.log(routeMatch)}, [routeMatch])

    const paths = {
        home: routeMatch.path,
        new: `${routeMatch.path}/new`,
        edit: `${routeMatch.path}/edit/:id`,
        view: `${routeMatch.path}/view/:id`,
        viewItem(item) {
            return `${routeMatch.path}/view/${item.id}`;
        },
        editItem(item) {
            return `${routeMatch.path}/edit/${item.id}`;
        }
    }

    return { paths, history, routeMatch }
}