
import Alert from "./Alert";

import { CardBody, Row, Col } from "reactstrap";

export const AlertsList = ({ alerts, avatar, onSave, onDelete, updateOrder, setCurrentAlert }) => {

    return (
        <CardBody>
            <Row>
                <Col className={"px-1"}>
                    {
                        alerts.map((alert, idx) =>
                            <Alert
                                idx={ idx }
                                alert={alert}
                                noOfAlerts={ alerts.length }
                                key={alert.id}
                                avatar={avatar}
                                updateOrder={ updateOrder }
                                onSave={onSave}
                                onDelete={onDelete}
                                setCurrentAlert={setCurrentAlert}
                            />
                        )
                    }
                </Col>
            </Row>
        </CardBody>
    )
}

export default AlertsList;
