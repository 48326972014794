import React, { useEffect } from "react";

import { useParams,
    // NavLink
} from "react-router-dom";
import {
    Card, Row, Col, CardHeader, Button, Badge,
    // CardFooter
} from "reactstrap";

import SubscriberDetails from "./SubscriberDetails";

import silhouette from "../../assets/img/avatars/blank_profile_img.png"

export const SubscriberProfile = ({ model, item, setItem, idx, showDetails, onView, onEdit }) => {

    let { id } = useParams();

    useEffect(() => {
        if (!item?.id && id) setItem({ id })
    }, [ item, id, setItem ])

    let margin = "mb-2 px-lg-3 ";

    if (idx === 0) margin += "mt--6 "
    if (idx === 1) margin += "mt-md--6"
    if (idx === 2) margin += "mt-lg--6"

    // let profileComplete = item?.instagram_username && item?.market_name;
    let subscriberDateStr = item ? model.getSubscriberDateStr(item) : "";

    let showProgress = (subscriber) => {
        return model.getProgressByStages(subscriber)
            .map(progress => {
                if (!progress) return null;
                return (
                    <Col key={ progress.label } xs={12} className={"d-flex justify-content-between"}>
                        <div>
                            <span className={`text-${progress.color}`}>●</span>
                            <small>{" "}{progress.label} </small>
                        </div>
                        <div>
                            {progress.status ? <Badge color={progress.color} pill>{progress.status}</Badge> : null}
                        </div>
                    </Col>
                )

            })
    }

    let showLatestProgress = (subscriber) => {
        let progress = model.getMostRecentProgress(subscriber);
        if (!progress) return null
        return (
            <Col xs={12} className={"d-flex justify-content-between"}>
                <div>
                    <span className={`text-${progress.color}`}>●</span>
                    <small>{" "}{progress.label} </small>
                </div>
                <div>
                    {progress.status ? <Badge color={progress.color} pill>{progress.status}</Badge> : null}
                </div>
            </Col>
        )
    }

    let showCardContent = (data) => {
        if (!data) return null;

        let headerClasses = "text-center border-0 pt-4 pt-md-4 pb-2 pb-md-0 ";
        if (showDetails) headerClasses += " pt-lg-5 px-lg-5 "
        return (
            <>
                <CardHeader className={ headerClasses }>
                    <Row className="d-flex justify-content-between">
                        <Col xs={3} className={"px-2"}>
                            <img
                                alt="..."
                                className="rounded-circle img-fluid w-80"
                                src={item.profile_picture_url || silhouette}
                                onError={ (e)=>{
                                    // e.target.onerror = null;
                                    // if (item.profile_picture_url) {
                                    //     console.log(item.profile_picture_url)
                                    //     console.log(e)
                                    // }
                                    e.target.src=silhouette} }
                            />
                        </Col>
                        <Col xs={5} className={`text-left pl-2 d-flex flex-column ${showDetails ? "pt-lg-4" : ""}`}>
                            <h5 className="h3 mb-0">
                                {item.market_name ? item.market_name : `${item.name} 사장`}

                            </h5>
                            <div className="h5 text-muted mb-0">{item.market_name ? `${item.name} 사장` : ""}</div>
                            <div><small>{subscriberDateStr}</small></div>

                        </Col>
                        <Col xs={4} className={"d-flex flex-column"}>
                            {
                                onEdit ?
                                    <Button
                                        className="mx-0 mb-2"
                                        color="default"
                                        onClick={e => {
                                            e.preventDefault();
                                            onEdit(item)
                                        }}
                                        size="sm"
                                    >
                                        EDIT
                                    </Button>
                                    :
                                    null
                                    // <Button
                                    //     className="mx-0 mb-2"
                                    //     color="default"
                                    //     onClick={e => {
                                    //         e.preventDefault();
                                    //         onView(item)
                                    //     }}
                                    //     size="sm"
                                    // >
                                    //     VIEW
                                    // </Button>
                            }

                            {/*<Button*/}
                            {/*    className="mx-0 mb-2"*/}
                            {/*    color="info"*/}
                            {/*    href="#pablo"*/}
                            {/*    onClick={(e) => e.preventDefault()}*/}
                            {/*    size="sm"*/}
                            {/*>*/}
                            {/*    인스타*/}
                            {/*</Button>*/}
                        </Col>

                    </Row>
                    <Row className={"justify-content-start text-left pt-2 pb-2 pt-md-3 pb-md-3"}>
                        {showDetails ? showProgress(item) : showLatestProgress(item) }
                    </Row>
                </CardHeader>
                {/*<Row className="justify-content-center">*/}
                {/*    <Col className="order-lg-2" lg="3">*/}
                {/*        <div className="card-profile-image">*/}
                {/*            <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                {/*                <img*/}
                {/*                    alt="..."*/}
                {/*                    className="rounded-circle"*/}
                {/*                    src={require("../../assets/img/avatars/elmarket_square_74.png").default}*/}
                {/*                />*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                { showDetails ? <SubscriberDetails item={ item } model={ model }/> : null}
                {/*{!profileComplete ?*/}
                {/*    <CardFooter className={"py-2"}>*/}
                {/*        <Row>*/}
                {/*            {*/}
                {/*                !item.instagram_username ?*/}
                {/*                    <Col xs={12}>*/}
                {/*                        <div>*/}
                {/*                            <span className="text-danger">●</span> <small className={"text-danger"}>No*/}
                {/*                            Instagram*/}
                {/*                            Username</small>*/}
                {/*                        </div>*/}
                {/*                    </Col>*/}
                {/*                    : null*/}
                {/*            }*/}
                {/*            {*/}
                {/*                !item.market_name ?*/}
                {/*                    <Col xs={12}>*/}
                {/*                        <div>*/}
                {/*                            <span className="text-danger">●</span> <small className={"text-danger"}>No*/}
                {/*                            Market Name</small>*/}
                {/*                        </div>*/}
                {/*                    </Col>*/}
                {/*                    : null*/}
                {/*            }*/}

                {/*        </Row>*/}
                {/*    </CardFooter>*/}
                {/*    : null}*/}
            </>

        )
    }

    return (
        <Col md={ showDetails ? 10 : 6} lg={showDetails ? 8 : 4}>
            <Card className={`card-profile ${margin}`} onClick={ () => {
                if (!onEdit) onView(item)
            }}>
                { showCardContent(item) }
            </Card>
        </Col>

    )
}
export default SubscriberProfile;
