import React, { useState } from "react";
import { Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import Select from "react-select";

export const SimpleSearchBox = ({ currentRefinement, refine, filters }) => {

    let [search, setSearch] = useState(currentRefinement);

    return (
        <Row>
            <Col xs={12}>
                <Form
                    className={"form-inline navbar-search-light"}
                    noValidate
                    action={""}
                    role={"search"}
                    onSubmit={e => e.preventDefault()}
                >

                    <FormGroup className="mb-0 col-12 col-md-6 col-lg-4 px-0">
                        <InputGroup className="input-group-alternative input-group-merge w-100 mr-0 mr-md-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fas fa-lg fa-search text-default"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Search"
                                type="search"
                                value={search}
                                onChange={event => {
                                    setSearch(event.currentTarget.value)
                                    refine(event.currentTarget.value)
                                }}
                            />
                        </InputGroup>
                    </FormGroup>
                    {
                        filters
                            ?
                            <Col xs={12} md={6} lg={4} className={"mb-0 mt-2 mt-md-0 pl-md-3 pl-0 pr-0"}>
                                <Select
                                    searchable={false}
                                    value={ filters.current }
                                    options={ filters.options }
                                    onChange={ (option) => {
                                        filters.set(option)
                                    }}
                                    styles={{
                                        valueContainer: (provided) => {
                                            return {
                                                ...provided,
                                                paddingTop: ".4rem",
                                                paddingBottom: ".4rem"
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            : null
                    }
                </Form>
            </Col>
        </Row>
    )
}

export default SimpleSearchBox;
