import React, { useContext } from "react";
import classnames from "classnames";

import PropTypes from "prop-types";
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Navbar,
    NavItem,
    Nav,
    Container
} from "reactstrap";

import FirebaseUserContext from "../../contexts/firebase-user-context-provider";
import GoogleUserContext from "../../contexts/google-user-context-provider";
// import NavbarSearchToggle from "./NavbarSearchToggle";
// import NavbarSearchBox from "./NavbarSearchBox";
import logo from "../../assets/img/avatars/GGum Plus TM.png";

export const AdminNavbar = ({ theme, sidenavOpen, toggleSidenav, onLogout }) => {

    let firebaseUser = useContext(FirebaseUserContext);
    let googleUser = useContext(GoogleUserContext);

    let profile = (googleUser && googleUser.getBasicProfile()),
        photoURL = (profile && profile.getImageUrl()) || (firebaseUser && firebaseUser.photoURL) || logo,
        displayName = (profile && profile.getName()) || (firebaseUser && firebaseUser.displayName);


    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-default": theme === "dark" },
                    { "navbar-light bg-secondary": theme === "light" }
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        {/*{indexName ? <NavbarSearchBox theme={theme} indexName={ indexName }/> : null }*/}
                        <Nav className="align-items-center ml-md-auto" navbar>
                            <NavItem className="">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: sidenavOpen },
                                        { "sidenav-toggler-dark": theme === "dark" }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line"/>
                                        <i className="sidenav-toggler-line"/>
                                        <i className="sidenav-toggler-line"/>
                                    </div>
                                </div>
                            </NavItem>
                            {/*{*/}
                            {/*    indexName*/}
                            {/*        ?*/}
                            {/*        <NavbarSearchToggle/>*/}
                            {/*        :*/}
                            {/*        null*/}
                            {/*}*/}

                        </Nav>
                        <Nav className="align-items-center ml-auto ml-md-0" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                                        {
                                            photoURL
                                                ?
                                                <span className="avatar avatar-sm rounded-circle bg-white">
                                                    <img alt="user_photo" src={photoURL}/>
                                                </span>
                                                : null
                                        }
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {displayName}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right>

                                    {/*<DropdownItem className="noti-title" header tag="div">*/}
                                    {/*  <h6 className="text-overflow m-0">Welcome!</h6>*/}
                                    {/*</DropdownItem>*/}

                                    {/*<DropdownItem*/}
                                    {/*  href="#pablo"*/}
                                    {/*  onClick={(e) => e.preventDefault()}*/}
                                    {/*>*/}
                                    {/*  <i className="ni ni-single-02" />*/}
                                    {/*  <span>My profile</span>*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem*/}
                                    {/*  href="#pablo"*/}
                                    {/*  onClick={(e) => e.preventDefault()}*/}
                                    {/*>*/}
                                    {/*  <i className="ni ni-settings-gear-65" />*/}
                                    {/*  <span>Settings</span>*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem*/}
                                    {/*  href="#pablo"*/}
                                    {/*  onClick={(e) => e.preventDefault()}*/}
                                    {/*>*/}
                                    {/*  <i className="ni ni-calendar-grid-58" />*/}
                                    {/*  <span>Activity</span>*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem*/}
                                    {/*  href="#pablo"*/}
                                    {/*  onClick={(e) => e.preventDefault()}*/}
                                    {/*>*/}
                                    {/*  <i className="ni ni-support-16" />*/}
                                    {/*  <span>Support</span>*/}
                                    {/*</DropdownItem>*/}
                                    {/*<DropdownItem divider />*/}
                                    <DropdownItem
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onLogout();
                                        }}
                                    >
                                        <i className="ni ni-user-run"/>
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

AdminNavbar.defaultProps = {
    toggleSidenav: () => {
    },
    sidenavOpen: false,
    theme: "dark",
};
AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf([ "dark", "light" ]),
};

export default AdminNavbar;
