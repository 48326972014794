import { useHistory } from "react-router";

export function useBreadcrumbs({ paths, setCurrent }) {

    let history = useHistory();

    let handleBreadcrumbClick = e => {
        e.preventDefault();
        setCurrent(null);
        history.push(paths.home);
    }

    return handleBreadcrumbClick
}