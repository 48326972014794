
import { Button } from "reactstrap";


export const LoginWithGoogle = ({ onGoogleSignIn }) => {

    return (
        <div className="btn-wrapper text-center">
            <Button
                className="btn-neutral btn-icon"
                color="default"
                href={"/dashboards"}
                onClick={ e => {
                    e.preventDefault();
                    onGoogleSignIn()
                } }
            >

                <span className="btn-inner--icon mr-1">

                    <img alt="google"
                         src={
                             require("../../assets/img/icons/common/google.svg").default}
                    />
                </span>
                <span className="btn-inner--text text-default">Sign in with Google</span>
            </Button>
        </div>
    )
}

export default LoginWithGoogle;
