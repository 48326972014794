import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useCollection } from "@nandorojo/swr-firestore";
import moment from "moment";
import { chain } from "lodash";

// import SubscriberFollows from "./Details/SubscriberFollows";

export const SubscriberMedia = ({ media }) => {

    const hasMediaUrl = !!media.media_url;
    const isVideo = media.media_type === "VIDEO" || (hasMediaUrl && media.media_url.includes("video"));

    let [show, setShow] = useState(hasMediaUrl && !isVideo);

    return (
        <>
            {
                show ?
                    <Col xs={4} style={{ padding: ".1rem"}}>
                        <img className="img-fluid"
                             src={ media.media_url }
                             alt={ media.id }
                             onError={(err) => {
                                 setShow(false);
                                 // console.log(media.media_url);
                                 // console.log(err);
                                 err.target.onerror = null}}
                        />
                    </Col>
                    :
                    null
            }
        </>
    )
}

export const SubscriberSnapshot = ({ item, model }) => {

    let oneWeekAgo = () => moment().subtract(7, "days"),
        oneWeekAgoStr = () => oneWeekAgo().format("YYYYMMDD");

    let [media, setMedia] = useState([]);

    // useEffect(() => {
    //     console.log(oneWeekAgo(), item.instagram_username)
    //     return fuego.db.collection("snapshots")
    //         .where("date", ">=", oneWeekAgo())
    //         .where("username", "==", item.instagram_username)
    //         .get()
    //         .then(snapshot => {
    //             console.log(snapshot.docs)
    //         })
    //         .catch(err => console.log(err))
    // }, [item.instagram_username])

    let { data } = useCollection(model.snapshotCollection, {
        orderBy: ["date", "desc"],
        where: [["date", ">=", oneWeekAgoStr()],
                ["username", "==", item.instagram_username]],
        parseDates: ["created"]

    }, {
        onSuccess(docs) { console.log(docs)}
    })

    useEffect(() => {
        if (!data) return;
        let media = chain(data)
            .reduce((obj, model) => {
                model.media.forEach(media => {
                    if (!obj[media.id]) obj[media.id] = media;
                })
                return obj;
            }, {})
            .map((val) => val)
            .orderBy(["timestamp"], ["desc"])
            .value()
        setMedia(media)
    }, [data])

    return (
        <>
            {/*<SubscriberFollows data={ data } />*/}
            {
            data && media.length > 0
                ?
                <Row className={"mx--4"}>
                    {media.map(media => <SubscriberMedia key={ media.id } media={ media } />)}
                </Row>

                :
                null
        }
        </>
    )

}

export default SubscriberSnapshot;
