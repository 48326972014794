import Subscriptions from "./Subscriptions"

import SubscriberProfile from "../components/Subscribers/SubscriberProfile";
import SubscriberForm from "../components/Subscribers/Form/SubscriberForm";
import SubscribersList from "../components/Subscribers/SubscribersList";
import Shoplinker from "../shoplink/Shoplinker";

export const Shoplinkers = () => {
    return <Subscriptions
        model={ Shoplinker }
        FormView={ SubscriberForm }
        ListView={ SubscribersList }
        ItemView={ SubscriberProfile }
    />
}

export default Shoplinkers;