

import Alerts from "./pages/Alerts";
import Blogs from "./pages/Blogs";
// import Employees from "./dashboards/Employees";
import Subscribers from "./pages/Subscribers"
import Shoplinkers from "./pages/Shoplinkers"
// import Users from "./dashboards/Users";

export const permissions = [
    "owner", "admin", "finance", "hr", "user", "teacher"
];

export const routes = [
    {
        collapse: true,
        name: "DREAM-MORE.KR",
        icon: "fa fa-desktop text-default",
        state: "websiteCollapse",
        views: [
            {
                path: "/alerts",
                name: "Alerts",
                // miniName: "",
                component: Alerts,
                layout: "/website",
                roles: ["owner"],
                icon: "fa fa-bell"
            },
            {
                path: "/blogs",
                name: "Blogs",
                // miniName: "",
                component: Blogs,
                layout: "/website",
                icon: "far fa-edit",
                roles: ["owner"]
            },

        ],
    },
    {
        collapse: true,
        name: "Dashboards",
        icon: "fa fa-chart-line text-default",
        state: "dashboardsCollapse",
        views: [
            {
                path: "/shoplinkers",
                name: "샵링크 수강생",
                // miniName: "",
                component: Shoplinkers,
                layout: "/dashboards",
                searchable: true,
                index: "shoplinkers",
                icon: "fa fa-user-graduate",
                roles: ["owner", "teacher"]
            },
            {
                path: "/subscribers",
                name: "아샵 수강생",
                // miniName: "",
                component: Subscribers,
                layout: "/dashboards",
                searchable: true,
                index: "subscribers",
                icon: "fa fa-user-graduate",
                roles: ["owner", "teacher"]
            },
            // {
            //     path: "/employees",
            //     name: "Employees",
            //     // miniName: "",
            //     component: Employees,
            //     layout: "/dashboards",
            //     roles: ["owner"]
            // },
            // {
            //     path: "/payments",
            //     name: "Payments",
            //     // miniName: "P",
            //     // component: Alternative,
            //     layout: "/dashboards",
            //     roles: ["owner"]
            // },
            // {
            //     path: "/users",
            //     name: "Users",
            //     // miniName: "P",
            //     component: Users,
            //     layout: "/dashboards",
            //     roles: ["owner"]
            // },
        ],
    },
];

export const getRoutesForClaims = claims => {

    let routeList = [];
    routes.forEach(route => {
        let permitted = {
            ...route,
            views: route.views.filter(view => view.roles.find(role => claims.includes(role)))
        }
        if (permitted.views.length > 0) routeList.push(permitted);
    })
    return routeList;

}

export default routes;