
import moment from "moment";
import randomInteger from "rand-int";
import { convertDates } from "../../firebase/convertDates";
import { getSelectedDropdowns } from "../../utilities/formsUtils";

import elmarket from "../../assets/img/avatars/elmarket_square_74.png";
import mozzimong from "../../assets/img/avatars/mozzimong_square_50.png";
import dream from "../../assets/img/avatars/GGum Plus TM.png";

import blog00 from "../../assets/img/blogs/blog_00.gif";
import blog01 from "../../assets/img/blogs/blog_01.png";
import blog02 from "../../assets/img/blogs/blog_02.png";
import blog04 from "../../assets/img/blogs/blog_04.png";
import blog05 from "../../assets/img/blogs/blog_05.jpeg";
import blog06 from "../../assets/img/blogs/blog_06.jpeg";
import blog07 from "../../assets/img/blogs/blog_07.png";
import blog09 from "../../assets/img/blogs/blog_09.png";
import blog10 from "../../assets/img/blogs/blog_10.png";
import blog11 from "../../assets/img/blogs/blog_11.png";

const collectionName = "blogs";
const pageName = "Blogs";
const indexName = "";

const newestFirst = true;

export const fields = {
    title: "title",
    url: "url",
    market: "market",
    date: "date",
    img: "img",
    ordering_index: "ordering_index",
    views: "views",
    isHidden: "isHidden"
}

export const dateFields = [
    fields.date
]

const defaults = {
    [fields.title]: "",
    // [fields.ordering_index]: 0,
    [fields.url]: "",
    [fields.market]: "Mozzimong",
    [fields.title]: "",
    [fields.img]: "",
    [fields.views]: `${randomInteger(19, 39) / 10}k`,
    [fields.isHidden]: false,
    [fields.date]: moment().toDate()
}


export const dropdowns = {
    [fields.market]: [
        { label: "모찌몽베베", value: "Mozzimong"},
        { label: "엘마켓", value: "ElMarket"},
        { label: "꿈을더하다", value: "Dream"},
    ]
}


export const getNew = () => {
    return {
        ...defaults,
        [fields.date]: moment().toDate(),
        [fields.views]: `${randomInteger(19, 39) / 10}k`
    }
}

export const labels = {
    [fields.title]: "Title",
    [fields.market]: "Author",
    [fields.url]: 'Link',
    [fields.img]: "Image",
    [fields.date]: "Date"
}

export const avatars = {
    ElMarket: elmarket,
    Mozzimong: mozzimong,
    Dream: dream
}

export const authors = {
    ElMarket: "엘마켓",
    Mozzimong: "모찌몽베베",
    Dream: "꿈을더하다"
}

export const authorsArray = [
    { label: "엘마켓", value: "ElMarket"},
    { label: "모찌몽베베", value: "Mozzimong"},
    { label: "꿈을더하다", value: "Dream"},
]

export const defaultBlogs = [
    {
        id: "ra3d5YSKSkZkNoOBVW2g",
        ordering_index: 0,
        title: "온라인 건물주란 말을 아시나요? 누구나 휴대폰 하나로 돈 벌 수 있는 시대에요!",
        img: blog11,
        market: "ElMarket",
        author: authors.ElMarket,
        author_img: avatars.ElMarket,
        url: "https://blog.naver.com/byebye10/222379118874",
        views: "1.9k",
        date: moment("2021.06.01 17:10", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "BI5s15qt3zy3RMfLpRw2",
        ordering_index: 1,
        title: "아지트샵 꿈을더하다 소개이벤트 성공후기 🤎",
        img: blog10,
        market: "ElMarket",
        author: authors.ElMarket,
        author_img: avatars.ElMarket,
        url: "https://blog.naver.com/byebye10/222376613574",
        views: "2.4k",
        date: moment("2021.05.31 11:30", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "Urep1DfQ2luTeZNjqVdc",
        ordering_index: 2,
        title: "꿈을더하다 아지트샵 - 가입 전 멘토선택 시 체크사항! 🤔",
        img: blog09,
        market: "ElMarket",
        author: authors.ElMarket,
        author_img: avatars.ElMarket,
        url: "https://blog.naver.com/byebye10/222365922661",
        views: "2.3k",
        date: moment("2021.05.25 12:18", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "ThoIvKYl8SkGEUbwuQ3e",
        ordering_index: 3,
        title: "아지트샵 꿈을더하다 팔로워를 사면 안되는 이유",
        img: blog06,
        market: "ElMarket",
        author: authors.ElMarket,
        author_img: avatars.ElMarket,
        url: "https://blog.naver.com/byebye10/222345381388",
        views: "2.9k",
        date: moment("2021.05.10 19:40", "YYYY.MM.DD HH:mm").toDate()
    },

    {
        id: "tggKIWuHehGJHZ3V52lh",
        ordering_index: 4,
        title: "하루 입금액 1억 3천만원! 아지트샵 공지의 주인공! 꿈을더하다에요:)",
        img: blog05,
        market: "Mozzimong",
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222325109746",
        views: "3.3k",
        date: moment("2021.04.27 18:32", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "2zbvxHVGJaVkwBB2Kou6",
        ordering_index: 5,
        title: "아지트샵 꿈을더하다 따라할 수 없는 이유! 후기로 보세요:)",
        img: blog04,
        market: "Mozzimong",
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222323608217",
        views: "2.3k",
        date: moment("2021.04.26 15:05", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "QlHjnl6uw5P9JfSKn0iY",
        ordering_index: 6,
        title: "공구를 배우는데 8명의 강사진이 있다구요? 한번쯤 읽어보세요:)",
        img: blog02,
        market: "Mozzimong",
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222317835141",
        views: "3.1k",
        date: moment("2021.04.21 14:02", "YYYY.MM.DD HH:mm").toDate()
    },

    {
        id: "kiZqNPdlRztDmb1fm7CF",
        ordering_index: 7,
        title: "공구왕 간증 후기 1탄 🤎",
        img: blog01,
        market: "Mozzimong",
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222309566883",
        views: "2.1k",
        date: moment("2021.04.14 12:23", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "TnZbGUZIPzQl38LhZSII",
        ordering_index: 8,
        market: "Mozzimong",
        title: "꿈을더하다 필독해주세요♥",
        img: blog00,
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222228667419",
        views: "2.4k",
        date: moment("2021.02.02 11:06", "YYYY.MM.DD HH:mm").toDate()
    },
    {
        id: "KTuyPXkYVL2Q1Iu9xQO7",
        ordering_index: 9,
        title: "꿈을더하다 네이버에서 검색해 보세요!",
        img: blog07,
        market: "Mozzimong",
        author: authors.Mozzimong,
        author_img: avatars.Mozzimong,
        url: "https://blog.naver.com/ayr1986/222346516686",
        views: "2.5k",
        date: moment("2021.05.11 15:18", "YYYY.MM.DD HH:mm").toDate()
    }
]

export const BlogModel = {
    collectionName,
    pageName,
    indexName,
    newestFirst,

    fields,
    labels,
    dropdowns,
    getNew,
    defaults,
    dateFields,

    avatars,
    authors,
    authorsArray,
    defaultBlogs,

    getItemPath(item) {
        return `${collectionName}/${item.id}`
    },

    convertDates(item) {
        return convertDates(item, dateFields)
    },
    getSelectedDropdowns(item) {
        return getSelectedDropdowns(dropdowns)(item)
    }
}

export default BlogModel;


