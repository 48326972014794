import SimpleHeader from "../components/Headers/SimpleHeader";
import SimpleButtons from "../components/Headers/SimpleButtons";
import SimpleButton from "../components/Headers/SimpleButton";
import { useCollection } from "@nandorojo/swr-firestore";
import useRemoteOp from "../hooks/useRemoteOp";

export default function ListRoute(props) {

    let { model, setCurrent, data, onView, View } = props
    let { searchTerm, filters, refineSearch, searchResults } = props

    let { pageName, indexName } = model;

    let { add: addOp } = useCollection("ops")
    let { onUpdateSubscribers, updateDisabled, updateLoading } = useRemoteOp({ addOp });

    return (
        <>
            <SimpleHeader
                name={pageName}
                indexName={indexName}
                searchTerm={searchTerm}
                refineSearch={refineSearch}
                filters={filters}
            >
                <SimpleButtons>
                    <SimpleButton
                        label={"UPDATE"}
                        onClick={onUpdateSubscribers}
                        disabled={!!updateDisabled}
                        isLoading={!!updateLoading}/>
                    {/*<SimpleButton*/}
                    {/*    label={"NEW"}*/}
                    {/*    onClick={onCreate}*/}
                    {/*    disabled={!!current}/>*/}
                </SimpleButtons>
            </SimpleHeader>
            <View
                model={ model }
                setCurrent={setCurrent}
                searchTerm={searchTerm}
                searchResults={searchResults}
                filters={filters}
                onView={onView}
                items={data}
            />
        </>

    )
}