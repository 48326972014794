import { FormGroup, Input, InputGroup } from "reactstrap";
import classnames from "classnames";

export const TextInput = ({ elementId, label, value, setValue, focus, setFocus, autoFocus, info, placeholder }) => {
    placeholder = placeholder || "";
    return (
        <FormGroup className={"border-default"}>
            <label
                className="form-control-label"
                htmlFor={elementId}
            >{label}</label>
            <InputGroup
                className={classnames("input-group-merge", {
                    focused: focus,
                })}
            >
                <Input
                    autocomplete={"off"}
                    style={{ color: "var(--dark-gray)"}}
                    autoFocus={autoFocus}
                    id={elementId}
                    type="text"
                    value={value}
                    placeholder={ placeholder }
                    onInput={e => setValue(e.currentTarget.value)}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            </InputGroup>
            {
                info
                    ?
                    <div className="valid-feedback d-block text-danger">{info}</div>
                    :
                    null
            }
        </FormGroup>
    )
}

export default TextInput;
