import SimpleHeader from "../components/Headers/SimpleHeader";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

export default function EditRoute({ paths, model, fuse, clone, setClone, setCurrent, onSave, onCancel, View }) {
    let { pageName, indexName } = model;

    let handleBreadcrumbClick = useBreadcrumbs({ setCurrent, paths })

    return (
        <>
            <SimpleHeader name={"EDIT"}
                          parentName={pageName}
                          onParentClick={handleBreadcrumbClick}/>
            <View
                model={ model }
                fuse={ fuse }
                item={clone}
                setItem={setClone}
                indexName={indexName}
                onSave={onSave}
                onCancel={() => onCancel(clone)}/>
        </>
    )

}