import React, { useState } from "react";
import { Card, CardHeader, Col, Row, Button } from "reactstrap";
import { useCollection, set, update, deleteDocument } from "@nandorojo/swr-firestore";
import { omitBy } from "lodash";

import AlertModel from "./AlertModel";
import AlertsList from "./AlertsList";
import AlertForm from "./Form/AlertForm";

export const AlertsGroupCard = ({ market, alerts, idx, paths, avatar }) => {

    let { collectionName, getNew } = AlertModel;

    let [ clone, setClone ] = useState(null);

    let { add } = useCollection(collectionName, {
        ignoreFirestoreDocumentSnapshotField: false,
        listen: true,
    }, {
    })

    let onCreate = () => setClone({...getNew(), market} )

    let onSave = (item, update) => {
        update = update || {};
        let doc = omitBy(update, (val, key) => key.startsWith("__"))
        if (!doc.ordering_index) doc.ordering_index = alerts.length;
        // console.log(doc)
        if (!item.id) add(doc)
        else set(`${collectionName}/${item.id}`, doc, { merge : true })
        setClone(null);
    }

    let updateOrder = (alert, oldIdx, newIdx) => {
        // console.log("updateOrder", alert, oldIdx, newIdx);
        update(`${collectionName}/${alerts[oldIdx].id}`,
            { ordering_index: newIdx })
        update(`${collectionName}/${alerts[newIdx].id}`,
            { ordering_index: oldIdx })

    }

    let onCancel = () => setClone(null);

    let onDelete = alert => deleteDocument(`${collectionName}/${alert.id}`)

    let margin = "";

    if (idx === 0) margin += "mt--6 "
    if (idx === 1) margin += "mt-md--6"
    if (idx === 2) margin += "mt-lg--6"

    return (
        <Card className={ margin }>
            <CardHeader>
                <Row className={"align-items-center"}>
                    <Col xs={8}>
                        <h3 className={"mb-1"}>{market}</h3>

                    </Col>
                    <Col xs={4} className={"text-right"}>
                        {
                            !clone
                            ?
                                <Button
                                    className={ clone ? "disabled": ""}
                                    color={"danger"}
                                    size={"sm"}
                                    onClick={ onCreate }>NEW</Button>
                                :
                                null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {
                            paths[market].map(path =>
                                <h5 className="text-muted mb-0" key={path}>{path}</h5>)
                        }
                    </Col>

                </Row>
            </CardHeader>

            {
                clone
                    ?
                    <AlertForm market={ market }
                               item={ clone }
                               setItem={ setClone }
                               onSave={ onSave }
                               onCancel={ onCancel }/>
                    :
                    <AlertsList market={ market }
                                alerts={ alerts }
                                avatar={ avatar }
                                onSave={ onSave }
                                onDelete={ onDelete }
                                updateOrder={ updateOrder }
                                setCurrentAlert={ setClone }/>
            }


        </Card>)
}

export default AlertsGroupCard;
