import { Switch, Route } from "react-router";

import { useDocument } from "@nandorojo/swr-firestore";

import useRoutePaths from "../hooks/useRoutePaths"
import useFuse from "../hooks/useFuse"
import useFilters from "../hooks/useFilters"
import useCRUD from "../hooks/useCRUD"

import NewRoute from "../routes/NewRoute"
import ViewRoute from "../routes/ViewRoute"
import EditRoute from "../routes/EditRoute"
import ListRoute from "../routes/ListRoute"

import useFetchCollection from "../hooks/useFetchCollection";

export default function Subscriptions(props) {

    let { model } = props;
    let { ListView, ItemView, FormView } = props;

    let { paths, routeMatch } = useRoutePaths();

    let { data, initialData, add } = useFetchCollection({ model })

    let {
        clone, current, setCurrent, setClone, onSave, onCancel, onView, onEdit
    } = useCRUD({ add, paths, routeMatch, ...model })

    let state = { clone, current, setCurrent, setClone }
    let handlers = { onSave, onCancel, onView, onEdit }

    let search /*{ fuse, searchTerm, searchResults, refineSearch }*/ = useFuse({ data, model })
    let filters = useFilters({ model })

    let { data: item } = useDocument(current?.id ? model.getItemPath(current) : null, {
        onSuccess(doc) {
            // console.log("subscriber in Subscribers", doc)
            setCurrent(doc)
        }
    });

    return (
        <Switch>
            <Route path={paths.new}>
                <NewRoute
                    paths={paths}
                    model={ model }
                    { ...search }
                    { ...state }
                    { ...handlers }
                    View={ FormView }
                />
            </Route>
            <Route path={paths.view}>
                <ViewRoute
                    View={ ItemView }
                    paths={ paths }
                    model={ model }
                    item={ item }
                    { ...state }
                    { ...handlers }
                />
            </Route>
            <Route path={paths.edit}>
                <EditRoute
                    paths={ paths }
                    model={ model }
                    { ...search }
                    { ...state }
                    { ...handlers }
                    View={ FormView }
                />
            </Route>
            <Route path={routeMatch.path}>
                <ListRoute
                    model={ model }
                    { ...state }
                    { ...handlers }
                    data={ data || initialData }
                    { ...search }
                    filters={ filters }
                    View={ ListView }
                />
            </Route>
        </Switch>
    )



}