import { FormGroup, Input, InputGroup } from "reactstrap";
import classnames from "classnames";
import { dehyphenate, hyphenateKoreanPhoneNumber } from "../../utilities/formsUtils";

export const TelInput = ({ elementId, label, value, setValue, focus, setFocus, autoFocus, info }) => {

    let onBlur = () => {
        setValue(hyphenateKoreanPhoneNumber(value))
    }

    let onFocus = () => {
        setValue(dehyphenate(value));
    }

    return (
        <FormGroup className={"border-default"}>
            <label
                className="form-control-label"
                htmlFor={elementId}
            >{label}</label>
            <InputGroup
                className={classnames("input-group-merge", {
                    focused: focus,
                })}
            >
                <Input
                    autoFocus={autoFocus}
                    id={elementId}
                    type="text"
                    value={value}
                    onInput={e => setValue(e.currentTarget.value)}
                    onFocus={(e) => {
                        onFocus(e.currentTarget.value)
                        setFocus(true)
                    }}
                    onBlur={(e) => {
                        onBlur(e.currentTarget.value)
                        setFocus(false)
                    }}
                />
            </InputGroup>
            {
                info
                    ?
                    <div className="valid-feedback d-block text-danger">{info}</div>
                    :
                    null
            }
        </FormGroup>
    )
}

export default TelInput;
