import React, { useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import { Switch, Route, useRouteMatch, useHistory } from "react-router";
import { fuego, useCollection, set, update, deleteDocument } from "@nandorojo/swr-firestore";
import { omitBy } from "lodash";

import SimpleHeader from "../components/Headers/SimpleHeader";
import SimpleButtons from "../components/Headers/SimpleButtons";

import BlogModel from "../components/Blogs/BlogModel";

import Blog from "../components/Blogs/Blog";
import BlogForm from "../components/Blogs/Form/BlogForm";
import SimpleButton from "../components/Headers/SimpleButton";
import useRemoteOp from "../hooks/useRemoteOp";



export const Blogs = () => {

    let firebase = fuego;

    let routeMatch = useRouteMatch(),
        history = useHistory();

    const paths = {
        home: routeMatch.path,
        new: `${routeMatch.path}/new`,
        edit: `${routeMatch.path}/edit/:id`,
        view: `${routeMatch.path}/view/:id`,
        viewItem(item) {
            return `${routeMatch.path}/view/${item.id}`;
        },
        editItem(item) {
            return `${routeMatch.path}/edit/${item.id}`;
        }
    }

    let [ clone, setClone ] = useState(null);

    let { fields, collectionName, getItemPath,
        getNew, dateFields } = BlogModel;

    let { data: blogs, add } = useCollection(collectionName, {
        listen: true,
        where: [],
        orderBy: [fields.ordering_index, "desc"],
        parseDates: dateFields
    }, {
        // initialData: defaultBlogs,
        // revalidateOnMount: true,
        onSuccess: console.log,
    })

    let db = firebase.firestore(),
        col = db.collection(collectionName);

    let { add: addOp } = useCollection("ops")
    let { onUpdateNaverBlog, updateDisabled, updateLoading } = useRemoteOp({ addOp });

    let handleBreadcrumbClick = e => {
        e.preventDefault();
        setClone(null);
        history.push(paths.home);
    }

    let onCreate = () => {
        let blog = getNew(),
            doc = col.doc();
        blog.id = doc.id;
        setClone(blog);
        history.push(paths.new)
    }

    let onEdit = (blog) => {
        setClone(blog);
        history.push(paths.editItem(blog))
    }

    let onSave = (item, update) => {
        update = update || {};
        let doc = omitBy(update, (val, key) => key.startsWith("__"))
        if (!doc.ordering_index) doc.ordering_index = blogs.length;
        // console.log(doc)
        if (!item.id) add(doc)
        else set(`${collectionName}/${item.id}`, doc, { merge : true })
        console.log("redirecting to ", paths.home)
        history.push(paths.home);
        setClone(null);
    }

    let onCancel = () => {
        setClone(null)
        history.push(paths.home);
    };

    let onDelete = item => deleteDocument(`${collectionName}/${item.id}`)

    let updateOrder = (item, oldIdx, newIdx) => {
        // console.log("updateOrder", item, oldIdx, newIdx);
        // let getPath = (idx) => `${collectionName}/${blogs[idx].id}`;
        let switchWith = blogs.find(blog => blog.ordering_index === newIdx)
        if (!switchWith) return;

        update(getItemPath(item), { ordering_index: newIdx });
        update(getItemPath(switchWith), { ordering_index: oldIdx });
    }


    return (
        <Switch>
            <Route path={paths.new}>
                <SimpleHeader name={"NEW"}
                              parentName={BlogModel.pageName}
                              onParentClick={handleBreadcrumbClick}/>
                <BlogForm item={ clone }
                          setItem={ setClone }
                          onSave={ onSave }
                          onCancel={ onCancel }/>

            </Route>
            <Route path={paths.edit}>
                <SimpleHeader name={"EDIT"}
                              parentName={BlogModel.pageName}
                              onParentClick={handleBreadcrumbClick}/>
                <BlogForm
                    item={ clone }
                    setItem={ setClone }
                    onSave={onSave}
                    onCancel={onCancel}/>

            </Route>
            <Route path={paths.home}>
                <SimpleHeader name={BlogModel.pageName}>
                    <SimpleButtons>
                        <SimpleButton
                            label={"UPDATE"}
                            onClick={onUpdateNaverBlog}
                            disabled={!!updateDisabled}
                            isLoading={!!updateLoading}/>
                        <SimpleButton label={"NEW"} onClick={onCreate} disabled={!!clone}/>
                    </SimpleButtons>
                </SimpleHeader>
                <Container fluid>
                    {
                        !blogs
                            ?
                            <Row className={"justify-content-center mt--6"}>
                                <Spinner color="light" style={{ width: '3rem', height: '3rem' }}/>
                            </Row>
                            :
                            <Row>
                                {blogs.map((blog, idx) =>
                                    <Blog key={blog.id}
                                          blog={blog}
                                          idx={idx}
                                          noOfBlogs={blogs.length}
                                          onDelete={onDelete}
                                          onEdit={onEdit}
                                          onSave={onSave}
                                          updateOrder={updateOrder}
                                    />)
                                }
                            </Row>
                    }

                </Container>
            </Route>
        </Switch>
    )
}

export default Blogs;
