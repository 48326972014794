import { firebaseConfig } from "./initFirebase";

const AUTH_SCOPES = [
    'email',
    'profile',
    // 'https://www.googleapis.com/auth/drive',
    // 'https://www.googleapis.com/auth/analytics.readonly',
]

export let gapiConfig = {
    client_id: '241290902224-maer4vagsiii8ougsgom9mf75egj38uj.apps.googleusercontent.com',
    apiKey: firebaseConfig.apiKey,
    scope: AUTH_SCOPES.join(' '),
    cookiepolicy: 'none',
    ux_mode: 'popup',
    redirect_uri: 'http://localhost:3000'
};

export const getGapiConfig = (host) => {
    let config = { ...gapiConfig },
        testing = ["localhost", "192.168.1.6"],
        production = ["dreammore-admin.web.app", "admin.dream-more.kr"];
    if (testing.includes(host)) config.redirect_uri = `http://${host}:3000`
    else if (production.includes(host)) config.redirect_uri = `https://${host}`
    return config;
}

export default gapiConfig;
