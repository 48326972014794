import { convertDates } from "../../firebase/convertDates";
import elmarket from "../../assets/img/avatars/elmarket_square_74.png";
import mozzimong from "../../assets/img/avatars/mozzimong_square_50.png";
import dream from "../../assets/img/avatars/GGum Plus TM.png";

const collectionName = "alerts";

const pageName = "Alerts";

const newestFirst = false;

const alertPaths = {
    Mozzimong: [
        "https://dream-more.kr/mozzimong",
        "https://dream-more.kr/m"
    ],
    ElMarket: [
        "https://dream-more.kr/elmarket",
        "https://dream-more.kr/e"
    ],
}

export const fields = {
    message: "message",
    link: "link",
    market: "market",
    img: "img",
    ordering_index: "ordering_index",
    isHidden: "isHidden"
}

export const dateFields = [

]

const defaults = {
    [fields.message]: "",
    [fields.link]: "",
    [fields.market]: "Mozzimong",
    [fields.img]: "",
    [fields.isHidden]: false
}

export const dropdowns = {
}

export const getNew = () => {
    return {
        ...defaults
    }
}

export const labels = {
    [fields.message]: "Title",
    [fields.market]: "Author",
    [fields.link]: "Link",
    [fields.img]: "Image",
}

export const defaultAlerts = [
    {
        id: "r29vLWqfICn1WEjmLzwx",
        idx: 0,
        ordering_index: 0,
        market: "ElMarket",
        message: "엘마켓 홈페이지 바로가기",
        img: elmarket,
        link: "http://s.agit.kr/shop/lsy8073/",
        isHidden: false
    },
    {
        id: "jJ4NyWOtxbTvuoCfxTjj",
        idx: 1,
        ordering_index: 0,
        market: "Mozzimong",
        message: "모찌몽베베 홈페이지 바로가기",
        img: mozzimong,
        link: "http://www.mozzimong.com/",
        isHidden: false
    }
]


let avatars = {
    ElMarket: elmarket,
    Mozzimong: mozzimong,
    Dream: dream
}

avatars["엘마켓"] = elmarket;
avatars["모찌몽베베"] = mozzimong;
avatars["꿈을더하다"] = dream;

export const AlertModel = {
    collectionName,
    pageName,
    newestFirst,
    alertPaths,
    fields,
    labels,
    dropdowns,
    getNew,
    defaults,
    dateFields,
    defaultAlerts,
    avatars,
    convertDates(item) {
        return convertDates(item, dateFields)
    }
}



export default AlertModel;
