import { Button } from "reactstrap";

export const SimpleButton = ({ label, onClick, disabled, isLoading }) => {
    return (
        <Button className={`btn-danger ${disabled ? "disabled": ""}`} size="sm" onClick={ onClick }>
            {
                isLoading ? <i className={`fas fa-circle-notch fa-spin`} /> : <span>{ label }</span>
            }

        </Button>
    )
}

export default SimpleButton;
