import React, { useEffect } from "react";
import { useDocument } from "@nandorojo/swr-firestore";

import { UserProvider } from "./contexts/user-context-provider";
import { ClaimsProvider } from "./contexts/claims-context-provider";

import Loading from "./pages/Loading";


export const UserLayer = ({ email, setEmail, setUser, loginMessages, setLoginError, signUserOut, children }) => {

    let { data: user, error, loading } = useDocument(email ? `users/${email}` : null)


    useEffect(() => {
        if (error) {
            setLoginError(loginMessages.ACCESS_DENIED)
            setEmail(null)
            signUserOut()
        }

        if (loading) {
            setLoginError(loginMessages.LOADING)
        }

        if (user) {
            setLoginError("")
            // console.log("setting user", user)
            setUser(user)
        }
    }, [
        user, error, loading, signUserOut,
        setEmail, setLoginError, setUser, loginMessages.ACCESS_DENIED, loginMessages.LOADING
    ])

    let show = (error, loading, user) => {
        if (error) return null;
        if (loading) return <Loading />;
        return (
            <UserProvider value={user}>
                <ClaimsProvider value={ user.claims }>
                    {children}
                </ClaimsProvider>
            </UserProvider>
        )
    }

    return (
        <>
            { show(error, loading, user)}
        </>
    )
}

export default UserLayer;