import Subscriptions from "./Subscriptions"

import SubscriberProfile from "../components/Subscribers/SubscriberProfile";
import SubscriberForm from "../components/Subscribers/Form/SubscriberForm";
import SubscribersList from "../components/Subscribers/SubscribersList";
import Subscriber from "../components/Subscribers/Subscriber";

export const Subscribers = () => {
    return <Subscriptions
        model={ Subscriber }
        FormView={ SubscriberForm }
        ListView={ SubscribersList }
        ItemView={ SubscriberProfile }
    />
}

export default Subscribers;
