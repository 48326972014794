import { CardBody, Form, Row, Col } from "reactstrap";
import progress from "../../../classes/progress"
import notes from "../../../classes/notes"

import DropdownInput from "../../Forms/DropdownInput";
import TextareaInput from "../../Forms/TextareaInput";
import TextInput from "../../Forms/TextInput";

export const StudentManagementForm = ({ model, subscriber, getInputProps }) => {

    let dropdownFields = Object.keys(progress.fields)

    let { fields, labels } = model;
    let { market_name, instagram_username } = fields;

    return (<CardBody>
        <Form>
            <Row>
                <Col md="6">
                    <TextInput
                        {...getInputProps(subscriber, market_name, "Market Name")}
                        info={"e.g. 엘마켓, 모찌몽베베"}

                    />
                </Col>
                <Col md={6}>
                    <TextInput
                        {...getInputProps(subscriber, instagram_username, "Instagram Username")}
                        info={"e.g. elsalife31, mozzimong_bebe"}
                    />
                </Col>


                {
                    dropdownFields.map(field => {
                        // console.log(field)
                        return (
                            <Col md={6} key={field}>
                                <DropdownInput
                                    {...getInputProps(subscriber, field, labels[field])}
                                    values={progress.dropdowns[field]}/>
                            </Col>)

                    })
                }
                <Col md={6}>
                    <TextareaInput
                        {...getInputProps(subscriber, notes.fields.teacher_comments, "Teacher Comments")}
                        rows={"3"}
                    />
                </Col>
            </Row>
        </Form>
    </CardBody>)
}

export default StudentManagementForm;
