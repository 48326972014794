import moment from "moment";

export const collectionName = "paymentInfo";
export const pageName = "Payment Info";
const claims = ["owner"];

export const fields = {
    name: "name",
    ssn: "ssn",
    bank_name_1: "bank_name_1",
    bank_acct_no_1: "bank_acct_no_1",
    bank_name_2: "bank_name_2",
    bank_acct_no_2: "bank_acct_no_2"
};

const bankNames = [
    "신한은행",
    "카카오뱅크",
    "국민은행",
    "기업은행",
    "부산은행",
    "우리은행",
    "농협은행"
]

export const dateFields = [];

export const defaults = {
    name: "",
    ssn: "",
    bank_name_1: "",
    bank_acct_no_1: "",
    bank_name_2: "",
    bank_acct_no_2: ""
};

export const dropdowns = {
    [fields.bank_name_1]: bankNames,
    [fields.bank_name_2]: bankNames
};

export const labels = {
    [fields.name]: "Name",
    [fields.ssn]: "주민등록번호",
    [fields.bank_name_1]: "은행명 1",
    [fields.bank_acct_no_1]: "계자번호 1",
    [fields.bank_name_2]: "은행명 2",
    [fields.bank_acct_no_2]: "계자번호 2",
};

export const getNew = () => {
    return {
        ...defaults,
        ...dateFields.reduce((obj, field) => { obj[field] = moment().toDate(); return obj; }, {})
    }
}

export const model = {
    collectionName,
    pageName,
    claims,
    fields,
    labels,
    dropdowns,
    getNew,
    defaults,
    dateFields
}

export default model;

