import { useState } from "react"
import { useHistory } from "react-router";
import { omitBy } from "lodash";
import { set } from "@nandorojo/swr-firestore";

export default function useCRUD({ add, routeMatch, paths, getItemPath }) {

    let history = useHistory()

    let [ current, setCurrent ] = useState(null);
    let [ clone, setClone ] = useState(null);


    let onCancel = (model) => {
        setClone(null);
        console.log(routeMatch.path)
        if (model) history.push(paths.viewItem(model))
        else history.push(routeMatch.path)
    }

    let onEdit = (model) => {
        setClone(model);
        history.push(paths.editItem(model))
    }

    let onView = (model) => {
        setCurrent(model)
        history.push(paths.viewItem(model))
    }

    let onSave = async (item, update) => {
        let doc = omitBy((update || {}), (val, key) => key.startsWith("__"))
        // console.log(doc)
        if (!item.id) add(doc)
        else set(getItemPath(item), doc, { merge: true })
        setCurrent({...item, ...update})
        setClone(null);
        history.push(paths.viewItem(item));
    }

    return {
        clone,
        current,
        setClone,
        setCurrent,
        onSave,
        onCancel,
        onView,
        onEdit
    }
}