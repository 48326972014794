import firebase from 'firebase/app';
// import { Fuego } from '@nandorojo/swr-firestore'
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
// import 'firebase/analytics';

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

export const firebaseConfig = {
    apiKey: "AIzaSyDChZEpPD5DmFI_kdwsYfytyIPn-SV4AuM",
    authDomain: "supersimpleshop-71b5f.firebaseapp.com",
    databaseURL: "https://supersimpleshop-71b5f.firebaseio.com",
    projectId: "supersimpleshop-71b5f",
    storageBucket: "supersimpleshop-71b5f.appspot.com",
    messagingSenderId: "241290902224",
    appId: "1:241290902224:web:41ec18601f7b9922957cd5",
    measurementId: "G-V21PJPBL2J"
};

export class Fuego {
    constructor(config) {
        this.db = !firebase.apps.length
            ? firebase.initializeApp(config).firestore()
            : firebase.app().firestore();
        this.auth = firebase.auth;
        this.functions = firebase.functions;
        this.storage = firebase.storage;
        this.firestore = firebase.firestore;

    }
}

export const initFirebase = () => {
    // try {
    //     return firebase.initializeApp(firebaseConfig);
    // } catch (err) {
    //     return firebase.app()
    // }
    const fuego = new Fuego(firebaseConfig)
    fuego.db.enablePersistence()
    return fuego;

}

export default initFirebase;
