var warn =(function(oldConsWarn){
    return function (...text) {
        let skip = [
            "[use-collection]",
            "[get-collection]",
            "[get-document]"
        ]
        if (text.length && skip.some(exclude => text[0].includes(exclude))) return;
        oldConsWarn(...text);
        // Your code
    }

    // return {
    //     log: function(...text){
    //         oldCons.log(...text);
    //         // Your code
    //     },
    //     info: function (...text) {
    //         oldCons.info(...text);
    //         // Your code
    //     },
    //     warn: function (...text) {
    //         let skip = [
    //             "[use-collection]",
    //             "[get-collection]",
    //             "[get-document]"
    //         ]
    //         if (text.length && skip.some(exclude => text[0].includes(exclude))) return;
    //         oldCons.warn(...text);
    //         // Your code
    //     },
    //     error: function (...text) {
    //         oldCons.error(...text);
    //         // Your code
    //     }
    // };
}(window.console.warn));

//Then redefine the old console
window.console.warn = warn;