import SimpleHeader from "../components/Headers/SimpleHeader";
import { Container, Row } from "reactstrap";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

export default function ViewRoute({ paths, item, model, setCurrent, onEdit, onCancel, View }) {
    let { pageName } = model;

    let handleBreadcrumbClick = useBreadcrumbs({ setCurrent, paths })

    return (
        <>
            <SimpleHeader
                name={"VIEW"}
                parentName={pageName}
                onParentClick={handleBreadcrumbClick}/>
            <Container fluid>
                <Row className={"justify-content-center"}>
                    <View
                        model={ model }
                        item={item}
                        setItem={setCurrent}
                        idx={0}
                        showDetails={true}
                        onEdit={onEdit}
                        onCancel={onCancel}/>
                </Row>
            </Container>
        </>
    )
}