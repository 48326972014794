import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { last } from "lodash";

import "./Dropzone.scss";


export const DropzoneInput = ({ value, setImgFile }) => {

    let [ img, setImg ] = useState(value);

    const onDrop = useCallback((acceptedFiles) => {
        let file = last(acceptedFiles);
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            // Do whatever you want with the file contents
            setImg(reader.result)
            setImgFile(reader.result)
        }
        reader.readAsDataURL(file)
    }, [ setImgFile ])

    let { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop
    })

    let preview = (src) => {
        return (
            <div className="dz-preview-cover">
                <img
                    src={src}
                    alt="..."
                    className="dz-preview-img"
                />
            </div>
        )
    }


    return (
        <div {...getRootProps()}
             className={`form-dropzone dropzone dropzone-single mb-3`}
        >
            <input {...getInputProps()}
                   type="file"
            />
            <div className={`dz-preview dz-preview-single`}>
                {
                    img || value ? preview(img || value) : null
                }
            </div>
            <div className="dz-default dz-message align-items-start flex-column"
                 style={{
                     display: "flex",
                     position: "relative",
                     justifyContent: "flex-end",
                     paddingLeft: 0,
                     paddingTop: "100%",
                     paddingBottom: 0,
                     height: "100%",
                     backgroundColor: "rgba(255, 255, 255, 0)",
                     color: "white",
                     opacity: 1,
                     "&:hover": {
                         opacity: 1
                     },

                 }}
            >
                <button className="btn btn-danger" type="button"
                        style={{
                            position: "absolute",
                            bottom: ".5rem",
                            left: ".5rem"
                        }}>
                    <i className={"fa fa-lg fa-camera"}/></button>
            </div>
        </div>
    )
}

export default DropzoneInput;
