import React, { useEffect, useState, useRef, useContext } from "react";

import {
    useLocation,
    Route,
    Switch
} from "react-router-dom";

import AdminNavbar from "./components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "./components/Sidebar/Sidebar.js";
import Home from "./pages/Home";


import ClaimsContext from "./contexts/claims-context-provider";

import { getRoutesForClaims } from "./routes";


export const AdminLayout = ({ onLogout }) => {

    let claims = useContext(ClaimsContext);
    let routes = getRoutesForClaims(claims);

    const [ sidenavOpen, setSidenavOpen ] = useState(false);

    const location = useLocation();
    const mainContentRef = useRef(null);

    useEffect(() => {
        // console.log(claims)
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [ location, claims ]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {

            let layouts = [ "/dashboards", "/website" ]
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (layouts.includes(prop.layout)) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        // render={() => {
                        //     // setSearchableDashboard(prop.searchable);
                        //     // setIndexName(prop.index);
                        //     // console.log(`matched ${prop.layout + prop.path}`)
                        //     return <prop.component
                        //         setIndexName={setIndexName}/>
                        // }}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };


    // toggles collapse between mini sidenav and normal
    const toggleSidenav = (e) => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSidenavOpen(!sidenavOpen);
    };

    return (
        <>
            <Sidebar
                routes={routes}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                    innerLink: "/",
                    imgSrc: require("./assets/img/brand/logo-black-400x154.png").default,
                    imgAlt: "꿈을더하다 로고",
                }}
            />

                <div className="main-content mb-6" ref={mainContentRef}>
                    <AdminNavbar
                        theme={"dark"}
                        toggleSidenav={toggleSidenav}
                        sidenavOpen={sidenavOpen}
                        onLogout={onLogout}
                    />

                    <Switch>
                        {getRoutes(routes)}
                        <Route path={"/"} >
                            <Home routes={ routes }/>
                        </Route>
                        {/*<Redirect from="*" to="/dashboards/subscribers"/>*/}
                    </Switch>
                    {/*<AdminFooter />*/}
                </div>

            {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav}/>
            ) : null}
        </>
    );
}

export default AdminLayout;
