import React, {  useState } from "react";
import {
    Card,
    Button,
    CardFooter,
    Container,
    CardBody,
    Col,
    Form,
    FormGroup,
    Row
} from "reactstrap";
import { fuego } from "@nandorojo/swr-firestore";
import moment from "moment";


import TextInput from "../../Forms/TextInput";
import DropdownInput from "../../Forms/DropdownInput";
import DatetimeInput from "../../Forms/DatetimeInput";
import DropzoneInput from "../../Forms/DropzoneInput";

import { uploadDataUrl } from "../../../firebase/uploadDataUrl";
import { getFormFocuses, setFormFocus, setFormValue } from "../../../utilities/formsUtils";
import BlogModel from "../BlogModel";

import "./BlogForm.scss";


export const BlogForm = ({ item, setItem, onSave, onCancel }) => {


    let { fields, labels, dropdowns, collectionName, getSelectedDropdowns } = BlogModel;

    let {
        title,
        url,
        market,
        date,
        img

    } = fields;


    let [focuses, setFocuses] = useState(getFormFocuses(item))

    let [selectedDropdowns] = useState(getSelectedDropdowns(item))

    let setFocus = setFormFocus(setFocuses);
    let setValue = setFormValue(setItem);

    const firebase = fuego,
        storage = firebase.storage(),
        getImgPath = (item, field) => {
            let id = item.id ? item.id : firebase.firestore.doc().id,
                date = moment().format("YYYYMMDD");
            return `img/${collectionName}/${id}/${field}/${date}`;
        };

    let [ imgFile, setImgFile ] = useState(null);
    let [ saving, setSaving ] = useState(false);

    let handleSave = () => {
        if (!url) {
            setFocus(url)(true)
            return;
        }
        if (!title) {
            setFocus(title)(true)
            return;
        }

        setSaving(true)

        if (imgFile) {
            uploadDataUrl(imgFile, getImgPath(item, "img"), storage, true)
                .then(downloadUrl => {
                    item.img = downloadUrl;
                    // console.log(update)
                    onSave(item, { ...item })
                    setImgFile(null)
                    setSaving(false)

                })
        } else {
            onSave(item, { ...item })
            setSaving(false)
        }
    }

    let getInputProps = (item, field, label) => {
        return {
            elementId: field,
            label: labels[field] || label,
            value: item[field],
            setValue: setValue(field),
            focus: focuses[field],
            setFocus: setFocus(field)
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col className={"mt--6"}>
                    <Card className={"blog-form"}>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col md="6">
                                        <DropdownInput
                                            elementId={ market }
                                            setValue={ setValue(market) }
                                            value={ selectedDropdowns[market] }
                                            label={ labels[market] || "Author"}
                                            values={ dropdowns[market] }/>
                                        <TextInput
                                            { ...getInputProps(item, url, "Link")}
                                            autofocus={true}
                                        />
                                        <DatetimeInput
                                            label={labels[date] || "Date"}
                                            value={ item[date]}
                                            setValue={ (val) => {
                                                setValue(date)(moment(val).toDate())
                                            }}
                                        />
                                        <TextInput
                                            { ...getInputProps(item, title, "Title")}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor={`blog-dropzone`}
                                            >Image</label>
                                            <DropzoneInput
                                                value={ item[img] }
                                                setImgFile={ setImgFile }/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Row>
                                <Col className={"d-flex justify-content-end"}>
                                    <Button size="sm" color={"secondary"}
                                            onClick={onCancel}>CANCEL</Button>
                                    <Button size="sm" color={"danger"} disabled={saving}
                                            onClick={handleSave}>
                                        {saving
                                            ?
                                            <i className={"fa fa-spinner fa-spin"}/>
                                            :
                                            "SAVE"
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}

export default BlogForm;
