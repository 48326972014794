import React, { useState } from "react";
import {
    Button,
    CardBody,
    Col,
    Form,
    FormGroup,
    Row
} from "reactstrap";
import { fuego } from "@nandorojo/swr-firestore";


import AlertModel from "../AlertModel";
import { getFormFocuses, setFormFocus, setFormValue } from "../../../utilities/formsUtils";
import { uploadDataUrl } from "../../../firebase/uploadDataUrl";
import moment from "moment";

import TextInput from "../../Forms/TextInput";
import TextareaInput from "../../Forms/TextareaInput";
import DropzoneInput from "../../Forms/DropzoneInput";


export const AlertForm = ({ item, setItem, onSave, onCancel }) => {

    let { fields, labels, collectionName } = AlertModel;

    let {
        message,
        link,
        img

    } = fields;

    let [focuses, setFocuses] = useState(getFormFocuses(item));
    let setFocus = setFormFocus(setFocuses);
    let setValue = setFormValue(setItem);

    const firebase = fuego,
        storage = firebase.storage(),
        db = firebase.firestore(),
        getImgPath = (item, field) => {
            let id = item.id ? item.id : db.collection(collectionName).doc().id,
                date = moment().format("YYYYMMDD");
            return `img/${collectionName}/${id}/${field}/${date}`;
        };

    let [ imgFile, setImgFile ] = useState(null);
    let [ saving, setSaving ] = useState(false);


    let handleSave = () => {
        if (!link) {
            setFocus(link)(true)
            return;
        }
        if (!message) {
            setFocus(message)(true)
            return;
        }

        setSaving(true)

        if (imgFile) {
            uploadDataUrl(imgFile, getImgPath(item, "img"), storage, true)
                .then(downloadUrl => {
                    item.img = downloadUrl;
                    // console.log(update)
                    onSave(item, {...item})
                    setImgFile(null)
                    setSaving(false)

                })
        } else {
            onSave(item, { ...item })
            setSaving(false)
        }
    }

    let getInputProps = (item, field, label) => {
        return {
            elementId: field,
            label: labels[field] || label,
            value: item[field],
            setValue: setValue(field),
            focus: focuses[field],
            setFocus: setFocus(field)
        }
    }

    return (
        <CardBody>
            <Form>
                <Row>
                    <Col md="12">
                        <TextInput
                            { ...getInputProps(item, link, "Link")}
                            autofocus={true}
                        />
                        <TextareaInput
                            { ...getInputProps(item, message, "Message")}
                        />
                    </Col>
                    <Col md={"12"}>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor={`blog-dropzone`}
                            >Image</label>
                            <DropzoneInput
                                value={ item[img] }
                                setImgFile={ setImgFile }/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row >
                    <Col className={"d-flex justify-content-end"}>
                        <Button size="sm" color={"secondary"}
                                onClick={ onCancel }>CANCEL</Button>
                        <Button size="sm" color={"danger"} disabled={saving}
                            onClick={ handleSave }>
                            { saving
                                ?
                                <i className={"fa fa-spinner fa-spin"} />
                                :
                                "SAVE"
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </CardBody>
    )
}

export default AlertForm;
