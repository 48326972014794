import fields from "./fields"

export const labels = {
    [fields.progress_basic_settings]: "가입인사,기본세팅",
    [fields.progress_insta_marketing]: "인스타마케팅",
    [fields.progress_cafe]: "선정방",
    [fields.progress_shopping_mall]: "쇼핑몰셋팅",
    [fields.progress_rooms]: "판매기초방&공지방 입장"
}

export default labels
