import { useCollection } from "@nandorojo/swr-firestore";
import { Container, Row, Col } from "reactstrap";
import { map, chain } from "lodash";

import SimpleHeader from "../components/Headers/SimpleHeader";
import AlertsGroupCard from "../components/Alerts/AlertGroupCard";

import AlertModel from "../components/Alerts/AlertModel";


export const Alerts = () => {

    let markets = [ "ElMarket", "Mozzimong" ];

    let sortAndGroup = (items, order, group) => {
        return chain(items)
            .sortBy(order)
            .groupBy(group)
            .value()
    }

    const { defaultAlerts, collectionName, pageName, avatars } = AlertModel;


    let { data, loading } = useCollection(collectionName, {
        listen: true,
    }, {
        initialData: defaultAlerts,
        revalidateOnMount: true
    })

    let makeAlertCards = (data) => {
        // console.log("alertGroups", alertGroups)
        let alertGroups = sortAndGroup(data, "ordering_index", "market")
        return map(markets, (market, idx) => {
            if (loading) return <h2>Loading</h2>
            return <Col md={6} lg={4} key={`${market}_alerts`}>
                <AlertsGroupCard
                    market={market}
                    alerts={alertGroups[market] || []}
                    avatar={avatars[market]}
                    idx={idx}
                    paths={AlertModel.alertPaths}
                />
            </Col>


        })
    }

    return (
        <>
            <SimpleHeader name={pageName}/>
            <Container fluid>
                <Row>
                    {
                        makeAlertCards(data)
                    }
                </Row>
            </Container>
        </>
    )
}

export default Alerts;
