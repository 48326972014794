export const uploadDataUrl = (dataUrl, destination, storage, verbose=false) => {
    return new Promise(function (resolve, reject) {
        var storageRef = storage.ref(destination);
        //Upload file
        var task = storageRef.putString(dataUrl, "data_url");
        //Update progress bar
        task.on('state_changed',
            function progress(snapshot){
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (verbose) console.log('Upload is ' + progress + '% done');
            },
            function error(err){
                reject(err);
            },
            function complete(){
                task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    if (verbose) console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            }
        );
    });
}

export default uploadDataUrl;
