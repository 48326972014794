
import {
    Col,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardImgOverlay,
    DropdownToggle,
    DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import moment from "moment";

import BlogModel from "./BlogModel";

export const Blog = ({ blog, idx, noOfBlogs, onDelete, onEdit, onSave, updateOrder }) => {

    let { authors, avatars } = BlogModel;

    let margin = "";

    if (idx === 0) margin += "mt--6 "
    if (idx === 1) margin += "mt-md--6"
    if (idx === 2) margin += "mt-lg--6"

    let opacity = blog.isHidden ? " opacity-5 " : "";

    let handleEdit = e => {
        e.preventDefault();
        onEdit(blog)
    }

    let onHide = (e) => {
        e.preventDefault();
        onSave(blog, { isHidden: !blog.isHidden })
    }

    let handleDelete = e => {
        e.preventDefault();
        onDelete(blog);
    }

    let onMoveUp = e => {
        e.preventDefault();
        updateOrder(blog, blog.ordering_index,  blog.ordering_index + 1)
    }

    let onMoveDown = e => {
        e.preventDefault();
        updateOrder(blog, blog.ordering_index, blog.ordering_index - 1)
    }

    return (
        <Col md={6} lg={4}>
            <Card className={ margin + opacity }>
                <CardImg top width={"100%"} src={ blog.img } alt={"cover_image"}/>
                <CardImgOverlay>
                    <UncontrolledDropdown direction={"left"} className={ "float-right" }>
                        <DropdownToggle
                            color="danger"
                            className="btn-sm mr-0"
                        >
                            <i className={"fa fa-lg fa-ellipsis-h"}/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/" onClick={handleEdit}>
                                Edit
                            </DropdownItem>
                            {
                                idx !== 0
                                    ?
                                    <DropdownItem href="/" onClick={onMoveUp}>
                                        Move Up
                                    </DropdownItem>
                                    :
                                    null
                            }
                            {
                                idx !== (noOfBlogs - 1)
                                    ?
                                    <DropdownItem href="/" onClick={onMoveDown}>
                                        Move Down
                                    </DropdownItem>
                                    :
                                    null
                            }



                            <DropdownItem href="/" onClick={onHide}>
                                {blog.isHidden ? "Unhide" : "Hide"}
                            </DropdownItem>
                            <DropdownItem href="/" onClick={handleDelete}>
                                Delete
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </CardImgOverlay>
                <CardBody>
                    <CardTitle tag={"h4"} className={"text-danger"}
                               style={{
                                   fontFamily: "\"Noto Sans KR\", sans-serif",
                                   fontSize: "1.2rem"}}>{ blog.title }</CardTitle>
                    <div className={"d-flex justify-content-between"}>
                        <div className="author d-flex">
                            <img
                                alt="..."
                                className="avatar img-raised bg-white border-light mr-2"
                                src={ avatars[blog.market] }
                                style={{ width: "45px", height: "45px",
                                    backgroundImage: "none",
                                    padding: 0,
                                    borderRadius: "50%",
                                    border: "1px solid var(--primary)"}}
                            />
                            <div className={"author-name-and-date flex-column"}>
                                <div className={"name"} style={{
                                    fontSize: "1.1em",
                                    fontWeight: "700",
                                    lineHeight: "1.15",
                                    maxWidth: "11em",
                                    overflow: "hidden",
                                    paddingTop: "3px",
                                    textOverflow: "ellipsis"
                                }}>{ authors[blog.market] }</div>
                                <div className={"date stats text-muted"} style={{fontSize: ".8em"}}>{ moment(blog.date).format("YYYY. M. D.") }</div>
                            </div>
                        </div>
                        <div className="stats stats-right opacity-8" style={{lineHeight: "30px", fontSize: ".8em"}}>
                            <i className="ni ni-favourite-28" style={{marginRight: "5px",
                                position: "relative",
                                top: "2px"}}/>
                            { blog.views }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default Blog;
