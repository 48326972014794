import React, { useState, useContext,
    useEffect
} from "react";
import {
    Col, Card, Row, Button, ButtonDropdown, CardBody,
    DropdownMenu, DropdownItem, DropdownToggle,
    CardFooter,
    CardHeader, Spinner
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDocument } from "@nandorojo/swr-firestore";

import BasicProfileForm from "./BasicProfileForm";
import StudentManagementForm from "./StudentManagementForm";
import AgitShopForm from "./AgitShopForm";
import PaymentInfoForm from "./PaymentInfoForm";

import ClaimsContext from "../../../contexts/claims-context-provider";

import { setFormFocus, setFormValue, getFormFocuses } from "../../../utilities/formsUtils";


export const SubscriberForm = ({ item, model, setItem, onSave, onCancel, fuse }) => {

    let claims = useContext(ClaimsContext);

    let params = useParams();
    let { getItemPath, dateFields, labels, formTab } = model;

    let id = params.id || item.id;

    let [focuses, setFocuses] = useState(getFormFocuses(item))

    let setFocus = setFormFocus(setFocuses);
    let setValue = setFormValue(setItem);

    let { unsubscribe } = useDocument(id ? getItemPath({ id }) : null, {
        parseDates: dateFields,
        onSuccess(doc) {
            setItem(doc)
            // console.log("subscriber", doc)
        }
    });

    useEffect(() => {
        if (unsubscribe) return unsubscribe;
    }, [unsubscribe])

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);


    let views = [
        { label: "Basic Profile", claims: ["owner"]},
        { label: "수강생 관리", claims: ["owner", "teacher"] },
        { label: formTab, claims: ["owner"] },
        // { label: "Instagram", claims: ["owner"] },
        // { label: "Kakao", claims: ["owner"] },
        { label: "Payment Info", claims: ["owner"], hideFooter: true },
        // { label: "Address", claims: ["owner", "teacher"] },
    ]

    let [activeView, setActiveView] = useState(views[0].label);
    let [hideFooter, setHideFooter] = useState(views[0].hideFooter);

    // useEffect(() => {
    //     console.log("paymentItem", paymentItem)
    // }, [paymentItem])

    let [saving, setSaving] = useState(false);

    let handleSave = () => {
        setSaving(true)
        onSave(item, item).then(() => setSaving(false))
    }

    let getInputProps = (item, field, label) => {
        return {
            elementId: field,
            label: labels[field] || label,
            value: item[field],
            setValue: setValue(field),
            focus: focuses[field],
            setFocus: setFocus(field)
        }
    }


    let showView = (view) => {

        if (!item) return <CardBody className={"text-center"}><Spinner /></CardBody>

        switch (view) {
            case "Payment Info":
                return <PaymentInfoForm
                    model={ model }
                    getInputProps={ getInputProps }
                    subscriber={ item }
                    onCancel={ () => onCancel(item) }
                    onSave={ handleSave }
                />
            case "수강생 관리":
                return <StudentManagementForm
                    model={ model }
                    subscriber={ item }
                    getInputProps={ getInputProps }/>
            case "아지트샵":
            case "샵링크":
                return <AgitShopForm
                    fuse={ fuse }
                    model={ model }
                    subscriber={ item }
                    setValue={ setValue }
                    getInputProps={ getInputProps }/>
            case "Basic Profile":
            default:
                return <BasicProfileForm
                    model={ model }
                    subscriber={ item }
                    getInputProps={ getInputProps }
                    setFocus={ setFocus }
                />
        }
    }


    return (
        <Col className={"mt--6"}>
            <Card className={"blog-form"}>
                <CardHeader className={"pl-1"}>
                    <Row>
                        <Col xs={6}>
                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret
                                                className={"heading-small text-muted"}
                                                color={"white"}
                                                style={{ fontSize: ".88rem"}}>
                                    { activeView }{" "}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        views
                                            .filter(view => claims.some(claim => view.claims.includes(claim)))
                                            .map(view => {
                                                if (view.label === activeView) return null;
                                                return <DropdownItem key={ view.label }
                                                                     onClick={e => {
                                                                         e.preventDefault();
                                                                         setActiveView(view.label)
                                                                         setHideFooter(view.hideFooter)
                                                                     }}>{ view.label }</DropdownItem>
                                            })
                                    }
                                </DropdownMenu>
                            </ButtonDropdown>
                        </Col>
                        {
                            hideFooter
                            ?
                                null
                                :
                                <Col xs={6} className={"d-flex justify-content-end"}>
                                    <Button size="sm" color={"secondary"} outline
                                            onClick={onCancel}>CANCEL</Button>
                                    <Button size="sm" color={"danger"} disabled={saving}
                                            onClick={handleSave}>
                                        {saving
                                            ?
                                            <i className={"fa fa-spinner fa-spin"}/>
                                            :
                                            " SAVE "
                                        }
                                    </Button>
                                </Col>
                        }

                    </Row>

                </CardHeader>
                { showView(activeView) }
                {
                    hideFooter ? null :
                        <CardFooter>
                        <Row>
                            <Col className={"d-flex justify-content-end"}>
                                <Button size="sm" color={"secondary"} outline
                                        onClick={onCancel}>CANCEL</Button>
                                <Button size="sm" color={"danger"} disabled={saving}
                                        onClick={handleSave}>
                                    {saving
                                        ?
                                        <i className={"fa fa-spinner fa-spin"}/>
                                        :
                                        "SAVE"
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                }

            </Card>
        </Col>
    )
}

export default SubscriberForm;
