
import {
    Container,
    Row,
    Col
} from "reactstrap";

import SimpleBreadcrumbs from "./SimpleBreadcrumbs";
import SimpleSearchBox from "./SimpleSearchBox";


export const SimpleHeader = ({ name, parentName, onParentClick, children, indexName, searchTerm, refineSearch, filters }) => {
    let paddingBottom = indexName ? "pb-7" : "pb-6";
    return (
        <>
            <div className={`header header-dark bg-default ${paddingBottom} content__title content__title--calendar`}>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                {
                                    parentName || true
                                        ?
                                        <SimpleBreadcrumbs
                                            onParentClick={ onParentClick }
                                            parentName={parentName} name={name}/>
                                        :
                                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                                            {name}
                                        </h6>
                                }
                            </Col>
                            {
                                children
                            }
                        </Row>
                        { refineSearch ? <SimpleSearchBox
                            filters={ filters }
                            currentRefinement={ searchTerm }
                            refine={ refineSearch }/> : null }
                    </div>
                </Container>
            </div>
        </>
    );
}

export default SimpleHeader;
