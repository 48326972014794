import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { isObject } from "lodash";

export const DropdownInput = ({ elementId, label, values, value, setValue, info, allowSearch, placeholder }) => {

    let options = {
        placeholder: placeholder || "Select one",
    };

    if (!allowSearch) options.minimumResultsForSearch = -1;

    let [selectedValue, setSelectedValue] = useState(isObject(value) ? value : {label: value, value })


    useEffect(() => {
        // console.log("value", value)
        setSelectedValue(isObject(value) ? value : {label: value, value })
    }, [value])

    // useEffect(() => {
    //     console.log("rendering")
    // }, [])

    return (
        <FormGroup >
            <label
                className="form-control-label"
                htmlFor={elementId}
            >{ label }</label>
            <Select
                id={elementId}
                className="form-control px-0 pt-0 border-0"
                value={ selectedValue }
                options={ values.map(val => {
                    if (isObject(val)) return val;
                    return { label: val, value: val}
                }) }
                // onInputChange={val => refine(val)}
                onChange={(option) => {
                    // console.log(option);
                    setSelectedValue(option);
                    // console.log("setting value", option.value)
                    setValue(option.value)
                    // console.log(value)
                }}

            />
            {
                info
                    ?
                    <div className="valid-feedback d-block text-danger">{info}</div>
                    :
                    null
            }
        </FormGroup>

    )
}

export default DropdownInput;
