import { useState } from "react"

export default function useFilters({ model }) {
    let { filterers } = model;
    let [currentFilterer, setCurrentFilterer] = useState(filterers[0]);

    let filters = {
        options: filterers,
        current: currentFilterer,
        set: setCurrentFilterer
    }

    return filters
}