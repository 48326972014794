import fields from "./fields"

export const dropdowns = {
    [fields.progress_basic_settings]: [
        "가입인사",
        "아지트샵세팅",
        "모바일쇼핑몰",
        "클릭수익 확인",
        "적립금 이벤트",
        "기타이벤트",
        "모두완료"
    ],
    [fields.progress_insta_marketing]: [
        "피드채우기",
        "본계키우기제출"
    ],
    [fields.progress_cafe]: [
        "입장",
        "1일차",
        "2일차",
        "3일차",
        "4일차",
        "5일차",
        "6일차",
        "7일차",
        "졸업(이야기방)"
    ],
    [fields.progress_shopping_mall]: [
        "세팅중",
        "세팅완료"
    ],
    [fields.progress_rooms]: [
        "확인중",
        "입장완료",
        "교육완료(판매질문방,단톡입장)"
    ]
}

export default dropdowns
