import { Col } from "reactstrap";

export const SimpleButtons = ({ children }) => {
    return (
        <Col className="mt-1 mt-md-1 text-md-right d-flex justify-content-end" lg="6" xs="5">
            { children }
        </Col>
    )
}

export default SimpleButtons;
