import React, { useState,
    // useEffect
} from "react";
import { CardBody, Form, Row, Col } from "reactstrap";

import TextInput from "../../Forms/TextInput";
import TelInput from "../../Forms/TelInput";
import ToggleInputCheckbox from "../../Forms/ToggleInputCheckbox";
import TextareaInput from "../../Forms/TextareaInput";


export const BasicProfileForm = ({ model, subscriber, getInputProps, setFocus }) => {

    let [ showPaymentName, setShowPaymentName ] = useState(false);

    let { fields } = model;

    let {
        name,
        payment_name,
        market_name,
        mall_id,
        instagram_username,
        mobile,
        self_intro,
        notes

    } = fields;


    return (
        <CardBody>
            <Form>
                <Row>
                    <Col md="6">
                        <TextInput
                            { ...getInputProps(subscriber, name, "Name")}
                        />
                        <ToggleInputCheckbox
                            elementId={"show-payment-name"}
                            setShow={setShowPaymentName}
                            onChange={(show) => {
                                setFocus(payment_name)(show)
                            }}/>

                        {showPaymentName ?
                            <TextInput
                                { ...getInputProps(subscriber, payment_name, "Payment Name") }
                                autoFocus={ true }
                                info={"다른 사람의 이름으로 결제 한 경우 Name을 실명으로 " +
                                "변경하고 여기에 결제한 이름을 입력하세요."}
                        /> : null}
                    </Col>
                    <Col md={"6"}>
                        <TextInput
                            { ...getInputProps(subscriber, market_name, "Market Name")}
                            info={"e.g. 엘마켓, 모찌몽베베"}

                        />
                    </Col>
                    <Col md={"6"}>
                        <TextInput
                            { ...getInputProps(subscriber, mall_id, "Agit Shop ID")}
                            info={"e.g. lsy8073, lovemom"}
                        />
                    </Col>
                    <Col md={"6"}>
                        <TextInput
                            {...getInputProps(subscriber, instagram_username, "Instagram Username")}
                            info={"e.g. elsalife31, mozzimong_bebe"}
                        />
                    </Col>
                    <Col md={"6"}>
                        <TelInput
                            {...getInputProps(subscriber, mobile, "Mobile Phone Number")}
                            info={"e.g. 010-9999-8888"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={"6"}>
                        <TextareaInput
                            {...getInputProps(subscriber, self_intro, "Self Intro")}
                            rows={"3"}
                        />
                    </Col>
                    <Col md={"6"}>
                        <TextareaInput
                            {...getInputProps(subscriber, notes, "Notes")}
                            rows={"3"}
                        />
                    </Col>
                </Row>
            </Form>
        </CardBody>
    )
}

export default BasicProfileForm;

