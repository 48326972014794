import { Spinner } from "reactstrap"
import logo from "../assets/img/brand/logo-white-393x147.png"

export const Loading = () => {
    return (
        <div
            className={"d-flex pt-8 justify-content-center"}
            style={{
            backgroundColor: "var(--default)",
            height: "100vh",
            width: "100vw"
        }}>

            <div className={"d-flex flex-column px-4 align-items-center"}>
                <img className={"img-fluid mb-5"} src={ logo } alt=""/>
                <Spinner color="light" style={{ width: '3rem', height: '3rem' }}/>
            </div>
        </div>
    )

}

export default Loading;