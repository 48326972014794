import { useHistory } from "react-router";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export const SimpleBreadcrumbs = ({ parentName, name, onParentClick }) => {

    let history = useHistory();

    return (
        <Breadcrumb
            className="d-md-inline-block ml-lg-4"
            listClassName="breadcrumb-links breadcrumb-dark"
        >
            <BreadcrumbItem>
                <a href="#pablo"
                   onClick={
                       (e) => {
                           e.preventDefault()
                           history.push("/")
                       }
                   }>
                    <i className="fas fa-home" />
                </a>
            </BreadcrumbItem>
            {
                parentName
                ?
                    <BreadcrumbItem>
                        <a href="#pablo"
                           onClick={ onParentClick }>
                            {parentName}
                        </a>
                    </BreadcrumbItem>
                    :
                    null
            }

            <BreadcrumbItem aria-current="page" className="active">
                <strong>{name}</strong>
            </BreadcrumbItem>
        </Breadcrumb>
    )
}

export default SimpleBreadcrumbs;