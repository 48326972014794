import { Button, Col, Row } from "reactstrap";

import Subscriber from "../Subscriber";

import silhouette from "../../../assets/img/avatars/blank_profile_img.png";

export const MiniProfile = ({ subscriber, onDelete }) => {

    let { getSubscriberDateStr } = Subscriber;

    return (
        <Row className="d-flex justify-content-between mx-0 row border py-3">
            <Col xs={3} className={"px-2"}>
                <img
                    alt="..."
                    className="rounded-circle img-fluid"
                    src={subscriber.profile_picture_url || silhouette}
                />
            </Col>
            <Col xs={5} className={"text-left pl-2 d-flex flex-column"}>
                <h5 className="h3 mb-0">
                    {subscriber.name}

                </h5>
                <div className="h5 text-muted mb-0">{subscriber.market_name || ""}</div>
                <div><small>{getSubscriberDateStr(subscriber)}</small></div>

            </Col>
            <Col xs={4} className={"d-flex flex-column"}>
                <Button color="danger"
                        size="sm"
                        type="button"
                        onClick={ onDelete }
                >
                    <i className={"fa fa-lg fa-trash"}/>
                </Button>
            </Col>

        </Row>
    )
}

export default MiniProfile;
