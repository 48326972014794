import { Alert, Spinner } from "reactstrap";

export const OnLoginError = ({ error }) => {

    let messages = {
        LOADING: "Checking login status...",
        LOGGING_IN: "Logging in"
    }

    let isLoading = messages[error],
        errorMsg = isLoading ? messages[error] : error;

    return (
        <Alert className="alert-default mt-3">
            <span className="alert-text ml-1">
              {
                  isLoading ?
                      <Spinner className={"text-sm mr-2"} style={{ width: "1.5rem", height: "1.5rem" }}/>
                      :
                      null
              }

                { errorMsg }
            </span>
        </Alert>
    )
}

export default OnLoginError;
