
import {
    Container,
    Row,
    Col,
} from "reactstrap";


import LoginWithGoogle from "../components/Login/LoginWithGoogle";
import OnLoginError from "../components/Login/OnLoginError";

import AuthHeader from "../components/Headers/AuthHeader.js";
import Loading from "./Loading";

import "./Login.scss";

export const Login = ({ loginMessages, error, onError, onGoogleSignIn }) => {


    return (
        <>
            { (error === loginMessages.LOADING)
                ?
                <Loading />
                :
                <>
                    <AuthHeader/>
                    <Container className="mt--8 pb-5 login-container">
                        <Row className="justify-content-center">
                            <Col lg="5" md="7">

                                <LoginWithGoogle
                                    onGoogleSignIn={onGoogleSignIn}
                                    onError={onError}/>
                                {error
                                    ?
                                    <OnLoginError error={error}/>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </Container>
                </>
            }
        </>
    );
}

export default Login;
