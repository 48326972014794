import { ToastBody } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

import "./Alert.scss";

export const Alert = ({ alert, idx, noOfAlerts, onSave, onDelete, updateOrder, setCurrentAlert }) => {

    let bg = alert.theme ? ` bg-${alert.theme} ` : "",
        disabled = alert.isHidden ? " disabled " : "";


    let onHide = (e) => {
        e.preventDefault();
        onSave(alert, { isHidden: !alert.isHidden })
    }

    let onEdit = e => {
        e.preventDefault();
        setCurrentAlert(alert);
    }

    let handleDelete = e => {
        e.preventDefault();
        onDelete(alert);
    }

    let onMoveUp = e => {
        e.preventDefault();
        updateOrder(alert, idx, idx - 1)
    }

    let onMoveDown = e => {
        e.preventDefault();
        updateOrder(alert, idx, idx + 1)
    }

    return (
        <div className={"dream-toast-container"}>
            <div className={bg + disabled} style={{ width: "400px",
                maxWidth: "98%"}}>
                <div className="d-flex bg-white my-0 justify-content-end border border-danger rounded-top">
                    <UncontrolledDropdown direction={"left"}>
                        <DropdownToggle
                            color="white"
                            className="mr-0 pr-2 pl-3 mt-1 pt-1 pb-2 text-danger"
                            style={{ border: "none", boxShadow: "none" }}>
                            <i className={"fa fa-lg fa-ellipsis-h"}/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/" onClick={onEdit}>
                                Edit
                            </DropdownItem>
                            {
                                idx !== 0
                                    ?
                                    <DropdownItem href="/" onClick={onMoveUp}>
                                        Move Up
                                    </DropdownItem>
                                    :
                                    null
                            }
                            {
                                idx !== (noOfAlerts - 1)
                                    ?
                                    <DropdownItem href="/" onClick={onMoveDown}>
                                        Move Down
                                    </DropdownItem>
                                    :
                                    null
                            }

                            <DropdownItem href="/" onClick={onHide}>
                                {alert.isHidden ? "Unhide" : "Hide"}
                            </DropdownItem>
                            <DropdownItem href="/" onClick={handleDelete}>
                                Delete
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                </div>
                <ToastBody className="text-white bg-danger mb-4 rounded-bottom">
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <img src={alert.img} alt="" className={"ml-2 bg-white img-fluid rounded shadow-lg"}/>
                        <div className={"product-alert-text px-4 text-white text-center"}
                             style={{ wordBreak: "keep-all" }}>
                            {alert.message}
                        </div>
                        <div className={"product-alert-text text-white"}>
                            <i className={"fa fa-lg fa-angle-right pl-3"}/>
                        </div>
                    </div>
                </ToastBody>
            </div>
        </div>
    )
}

export default Alert;
