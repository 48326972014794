import React, { useState, useContext, useEffect } from "react";

import { model } from "../../PaymentInfo/model";
import { Button, CardBody, CardFooter, Col, Form, Row } from "reactstrap";
import TextInput from "../../Forms/TextInput";
import { getFormFocuses, setFormFocus, setFormValue } from "../../../utilities/formsUtils";
import DropdownInput from "../../Forms/DropdownInput";
import FirebaseContext from "../../../contexts/firebase-context-provider";
import { useParams } from "react-router-dom";


export const PaymentInfoForm = ({ onCancel, onSave }) => {

    let firebase = useContext(FirebaseContext);

    let [ item, setItem ] = useState({ ...model.getNew() })

    const { id } = useParams();


    useEffect(() => {

        if (id) {
            return firebase.firestore()
                .collection(model.collectionName)
                .doc(id)
                .onSnapshot(snapshot => {
                    console.log("snapshot", snapshot.data())
                    let doc = {
                        ...model.getNew(),
                        id
                    }
                    if (snapshot.exists) {
                        doc = model.convertDates({
                            ...snapshot.data(),
                            id
                        })
                    }
                    setItem(doc)
                })
        }
    }, [ firebase, id ])

    let { fields, labels, dropdowns } = model;

    let {
        name, ssn, bank_name_1,
        bank_acct_no_1,
        bank_name_2,
        bank_acct_no_2
    } = fields;

    console.log(labels)

    let [ focuses, setFocuses ] = useState(getFormFocuses(item))

    let setFocus = setFormFocus(setFocuses);
    let setValue = setFormValue(setItem)

    let [ saving, /* setSaving */ ] = useState(false);

    let handleSave = () => {

    }

    return (
        <>
            <CardBody>
                <Form>
                    <Row>
                        <Col md="6">
                            <TextInput
                                label={labels[name] || "Name"}
                                elementId={name}
                                focus={focuses[name]}
                                setFocus={setFocus(name)}
                                value={item[name]}
                                setValue={setValue(name)}
                            />
                        </Col>
                        <Col md="6">
                            <TextInput
                                label={labels[ssn] || "SSN"}
                                elementId={ssn}
                                focus={focuses[ssn]}
                                setFocus={setFocus(ssn)}
                                value={item[ssn]}
                                setValue={setValue(ssn)}
                                info={"e.g. 861010-1234567"}
                            />
                        </Col>

                        <Col md={"6"}>
                            <DropdownInput
                                elementId={bank_name_1}
                                setValue={setValue(bank_name_1)}
                                value={item[bank_name_1]}
                                label={labels[bank_name_1]}
                                values={dropdowns[bank_name_1]}/>
                        </Col>
                        <Col md={"6"}>
                            <TextInput
                                label={labels[bank_acct_no_1] || "Bank Acct. Number 1"}
                                elementId={bank_acct_no_1}
                                focus={focuses[bank_acct_no_1]}
                                setFocus={setFocus(bank_acct_no_1)}
                                value={item[bank_acct_no_1]}
                                setValue={setValue(bank_acct_no_1)}
                                info={"e.g. 110-630-123987"}
                            />
                        </Col>
                        <Col md={"6"}>
                            <DropdownInput
                                elementId={bank_name_2}
                                setValue={setValue(bank_name_2)}
                                value={item[bank_name_2]}
                                label={labels[bank_name_2]}
                                values={dropdowns[bank_name_2]}/>
                        </Col>
                        <Col md={"6"}>
                            <TextInput
                                label={labels[bank_acct_no_2] || "Bank Acct. Number 2"}
                                elementId={bank_acct_no_2}
                                focus={focuses[bank_acct_no_2]}
                                setFocus={setFocus(bank_acct_no_2)}
                                value={item[bank_acct_no_2]}
                                setValue={setValue(bank_acct_no_2)}
                                info={"e.g. 110-630-123987"}
                            />
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col className={"d-flex justify-content-end"}>
                        <Button size="sm" color={"secondary"}
                                onClick={onCancel}>CANCEL</Button>
                        <Button size="sm" color={"danger"} disabled={saving}
                                onClick={handleSave}>
                            {saving
                                ?
                                <i className={"fa fa-spinner fa-spin"}/>
                                :
                                "SAVE"
                            }
                        </Button>
                    </Col>
                </Row>
            </CardFooter>
        </>
    )
}

export default PaymentInfoForm;
