import React, {
    useState,
    useEffect
} from "react";
import { fuego } from "@nandorojo/swr-firestore";

import { GoogleAuthProvider } from "./init/initFirebase";
import { GoogleUserProvider } from "./contexts/google-user-context-provider";
import { FirebaseUserProvider } from "./contexts/firebase-user-context-provider";

import { getGapiConfig } from "./init/initGapi";

let HOST = window.location.hostname,
    gapiConfig = getGapiConfig(HOST);


export const GoogleLayer = ({
                                children,
                                setEmail,
                                setOnLogout,
                                loginMessages,
                                loginError,
                                setLoginError,
                                resetUser
                            }) => {

    const firebase = fuego;

    let [ googleUser, setGoogleUser ] = useState(null);
    let [ firebaseUser, setFirebaseUser ] = useState(null);

    useEffect(() => {
        if (loginError === loginMessages.ACCESS_DENIED) {
            setGoogleUser(null)
            setFirebaseUser(null)
        }
    }, [ loginError, loginMessages.ACCESS_DENIED ])

    useEffect(() => {
        setOnLogout(prevState => [
                ...prevState,
                () => setGoogleUser(null),
                () => setFirebaseUser(null)
            ]
        )
    }, [ setOnLogout ])

    useEffect(() => {
        function handleIsSignedIn(isSignedIn) {
            if (isSignedIn) {
                const auth2 = window.gapi.auth2.getAuthInstance()
                const currentUser = auth2.currentUser.get()
                setGoogleUser(currentUser)
                const profile = currentUser.getBasicProfile()
                console.log('gapi: user signed in!', {
                    name: profile.getName(),
                    photoURL: profile.getImageUrl(),
                    email: profile.getEmail(),
                })
                // setLoginError(prev => prev + "; gapi: user signed in")
                const authResponse = currentUser.getAuthResponse(true)
                const credential = GoogleAuthProvider.credential(
                    authResponse.id_token,
                    authResponse.access_token
                )

                firebase.auth().signInWithCredential(credential)
                    .then(({ user }) => {
                        if (user) {
                            setFirebaseUser(user);
                            // setLoginError(prev => {
                            //     return prev + "; firebase: user signed in"
                            // })
                            setEmail(user.email);
                            console.log('firebase: user signed in!', {
                                displayName: user.displayName,
                                email: user.email,
                                photoURL: user.photoURL,
                            })

                        }
                        // user.getIdTokenResult()
                        //     .then(idTokenResult => setClaims(idTokenResult.claims))
                    })
                    .catch(err => setLoginError(prev => {

                        return "this error here: " + err.message
                    }))

                // Try to make a request to Google Analytics!
                // gapi.client.analytics.management.accounts.list()
                //     .then((response) => {
                //         console.log('Google Analytics request successful!')
                //         if (response.result.items && response.result.items.length) {
                //             const accountNames = response.result.items.map(account => account.name)
                //             alert('Google Analytics account names: ' + accountNames.join(' '))
                //         }
                //     })
            } else {
                console.log('gapi: user is not signed in')
                resetUser(null)
                setLoginError(prev => prev === loginMessages.ACCESS_DENIED ? prev : "")
            }
        }

        new Promise(resolve => {
            window.gapi.load("client:auth2", () => resolve())
        })
            .then(() => {
                // setLoginError(prev => prev + "; gapi: client:auth2 loaded")
                console.log('gapi: client:auth2 loaded', window.gapi.client)
            })
            .then(() => {
                return window.gapi.client.init(gapiConfig)
            })
            .then(() => {
                const auth2 = window.gapi.auth2.getAuthInstance()
                auth2.isSignedIn.listen(handleIsSignedIn)
                handleIsSignedIn(auth2.isSignedIn.get())
            })
            .catch(err => {
                // return setLoginError(prev => {
                //     return prev + "; " + err.message
                // })
            })
    }, [ firebase, setLoginError, resetUser, setEmail, loginMessages.ACCESS_DENIED ])


    return (
        <GoogleUserProvider value={googleUser}>
            <FirebaseUserProvider value={firebaseUser}>
                {children}
            </FirebaseUserProvider>
        </GoogleUserProvider>)


}

export default GoogleLayer;
