// import React, { useEffect } from "react";
import { FormGroup } from "reactstrap";

export const ToggleInputCheckbox = ({ elementId, setShow, onChange }) => {

    return (
        <FormGroup className={"pl-1"}>
            <div className="custom-control custom-checkbox mb-3">
                <input
                    className="custom-control-input text-white"
                    defaultValue=""
                    id={ elementId }
                    type="checkbox"
                    onChange={(e) => {
                        setShow(e.target.checked);
                        onChange(e.target.checked);
                    }}
                />
                <label
                    className="custom-control-label"
                    htmlFor={ elementId }
                >
                    다른 사람 이름으로 결제함
                </label>

            </div>
        </FormGroup>
    )
}

export default ToggleInputCheckbox;
