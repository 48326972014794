import { reduce } from "lodash";

export const setFormFocus = (setFocuses) => {
    return (attr) => {
        return (val) => {
            setFocuses(prev => {
                return {
                    ...prev,
                    [attr]: val
                }
            })
        }
    }
}

export const setFormValue = (setModel) => {
    return (attr) => {
        return (val) => {
            setModel(prev => {
                console.log(prev)
                return {
                    ...prev,
                    [attr]: val
                }
            })
        }
    }
}

export const getFormFocuses = (model) => {
    return reduce(model, (obj, val, key) => {
        obj[key] = false;
        return obj;
    }, {})
}

export const getSelectedDropdowns = (dropdowns) => {
    return item => {
        return reduce(dropdowns, (obj, options, field) => {
            obj[field] = dropdowns[field].find(option => option.value === item[field]);
            return obj;
        }, {})
    }
}

export const hyphenateKoreanPhoneNumber = (value) => {
    let val = value.replace(/-/g, "");
    if (val.length > 9) {
        return [val.slice(0, -8), val.slice(-8, -4), val.slice(-4)].join("-");
    } else if (val.length > 8) {
        return [val.slice(0, -7), val.slice(-7, -4), val.slice(-4)].join("-");
    }
    return val;
}

export const hyphenateKoreanBankAccount = (value) => {
    let val = value.replace(/-/g, "");
    if (val.length > 9) {
        return [val.slice(0, -8), val.slice(-8, -4), val.slice(-4)].join("-");
    }
    return val;
}

export const hyphenateKoreanSSN = (value) => {
    let val = value.replace(/-/g, "");
    return [val.slice(0, 6), val.slice(6)].join("-")
}

export const dehyphenate = (value) => {
    return value.replace(/-/g, "")
}

export const dehyphenateDespace = (value) => {
    return value.replace(/-/g, "").replace(/ /g, "")
}