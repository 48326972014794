import Datetime from "react-datetime";
import { FormGroup } from "reactstrap";

export const DatetimeInput = ({ elementId, label, value, setValue, info }) => {
    return (
        <FormGroup>
            <label
                className="form-control-label"
                htmlFor={ elementId }
            >
                { label }
            </label>
            <Datetime
                id={ elementId }
                value={ value }
                // initialViewDate={ date }
                style={{ color: "var(--dark-gray)"}}
                timeFormat={false}
                dateFormat={ "MM/DD/YYYY" }
                onChange={ date => { setValue(date.toDate())}}
                closeOnSelect={ true }
            />
            {
                info
                    ?
                    <div className="valid-feedback d-block text-danger">{info}</div>
                    :
                    null
            }
        </FormGroup>
    )
}

export default DatetimeInput
